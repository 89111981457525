import { connect } from 'react-redux';
import { Recorder } from './index';
import { setMessagePanel } from '../../redux/actions/actions';

const mapDispatchToProps = dispatch => ({
  setStatusMessage: status => dispatch(setMessagePanel(status)),
});

const RecorderContainer = connect(
  null,
  mapDispatchToProps
)(Recorder);

export default RecorderContainer;
