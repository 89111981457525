import React, {Component} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Col, Row } from 'react-materialize';
import { Principal } from './components/principal';

export class App extends Component {
  render() {
    return (
      <BrowserRouter>    
        <Row style={{paddingTop:'4vh'}}>
          <Col s={1} />
          <Col s={10}>
            <Principal />
          </Col>
          <Col s={1} />
        </Row>
      </BrowserRouter>
    );
  };
}