import { INSTANCE } from "../";

export class LocutorAPI {

  static getRandPhrase() {
    return INSTANCE.get('/api/frases/locutor')
    .then(response => response.data);
  }

  static getPhraseStatic(locutor) {
    return INSTANCE.post('/api/frases/locutor-static',{locutor})
    .then(response => response.data);
  }

  static getSearchPhrase(frase, autor) {
    return INSTANCE.post('/api/frases/locutor/search', {frase, autor})
    .then(response => response.data);
  }

  static getSearchAudios(autor, texto) {
    console.log(texto)
    return INSTANCE.post('/api/audios/buscar/frase/', {autor, texto})
    .then((response) => {
      
      return response.data;
    });
  }

  static getRefinement(autor,dialog) {
    return INSTANCE.post('/api/frases/locutor/searchRefinement', {autor,dialog})
    .then(response => response.data);
  }

  static getRandPhraseBR() {
    return INSTANCE.get('/api/frases/br/locutor')
    .then(response => response.data);
  }

}