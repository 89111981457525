export const initialState = {
  rankPanel:{
    show: false,
    name: '',
    score: 0
  }, 
  messagePanel: {
    message: '',
    code: 0,
    showPanel: false
  },
  infoPanel: {
    userId: null,
    showPanel: false,
    speaker: {
      name: '',
      score: 0,
      time: {
        total: 0,
        scored: 0
      },
      numAudios: {
        total: 0,
        scored: 0,
        pending: 0
      }
    }    
  }
};