import { connect } from 'react-redux';
import  Audio  from './index';
import { setInfoPanel, setUserId, showInfoPanel, hideInfoPanel } from '../../redux/actions/actions';

const mapDispatchToProps = dispatch => ({
  showInfoPanel: () => dispatch(showInfoPanel()),
  hideInfoPanel: () => dispatch(hideInfoPanel()),
  setUserId: (userID) => dispatch(setUserId(userID)),
  setInfoPanel: (speaker) => dispatch(setInfoPanel(speaker))
});

const AudioContainer = connect(
  null,
  mapDispatchToProps
)(Audio);

export default AudioContainer;