import { connect } from 'react-redux';
import { StatusPanel } from './index';

const mapStateToProps = (state) => {  
  return {message: state.messagePanel.message,
  showPanel: state.messagePanel.showPanel} 
};

const PanelContainer = connect(
  mapStateToProps,
  null
)(StatusPanel);

export default PanelContainer;