import React, { Component } from "react";
import { Row, Col } from "react-materialize";


export class StatusPanel extends Component {

  render () {
    return (
      <div>
        {this.props.showPanel?
          <Row style={{borderStyle:'groove'}}>
            <Col s={2}>
              <div style={{fontWeight:'bold'}}>Estado:</div>
            </Col>
            <Col s={2} />
            <Col s={8}>
              <div>{this.props.message}</div>
            </Col>
          </Row>
        :null
        }
      </div>
    );
  }
}
