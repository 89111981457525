import { TYPES } from './actionTypes';

// -- rankPanel --
export function showPersonalRank() {
  return { type: TYPES.PERSONAL_RANK.SHOW };
}

export function hidePersonalRank() {
  return { type: TYPES.PERSONAL_RANK.HIDE };
}

export function setDataPersonalRank(data) {
  return {
    type: TYPES.PERSONAL_RANK.SET,
    name: data.name,
    score: data.score
  };
}

// -- messagePanel --

export function setMessagePanel(data) {
  return {
    type: TYPES.PANEL_MESSAGE.MESSAGE,
    message: data.message,
    code: data.code,
    showPanel: true
  };
}

// -- infoPanel --
export function showInfoPanel() {
  return {
    type: TYPES.PANEL_INFO.SHOW,
    showPanel: true
  };
}
export function hideInfoPanel() {
  return {
    type: TYPES.PANEL_INFO.SHOW,
    showPanel: false
  };
}
export function setInfoPanel(speaker) {
  return {
    type: TYPES.PANEL_INFO.SET,
    speaker
  };
}
export function setUserId(userID) {
  return {
    type: TYPES.PANEL_INFO.SET_USER_ID,
    userID
  };
}
