/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Icon
} from 'react-materialize';
import queryString from 'query-string';

import { AudioAPI } from '../../services/api/audio-api';
import { UsuarioAPI } from '../../services/api/usuario-api';
import RecorderContainer from '../recorder/RecorderContainer';

const timeToRedirect = process.env.REACT_APP_TIME_TO_REDIRECT || 120;
const maxNumGrabaciones = process.env.REACT_APP_MAX_NUM_GRABACIONES || 10;
const minNumGrabaciones = process.env.REACT_APP_MIN_NUM_GRABACIONES || 5;

class Audio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensaje: 'Validando',
      frase: '',
      parametrosOmni: {},
      numGrabacion: null,
      mostrarBotonRedireccion: false,
      mostrarBotonGrabar: false,
      haGrabado: false
    };
    this.recuperarFrase = this.recuperarFrase.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.iniciarDatos = this.iniciarDatos.bind(this);
    this.redireccionar = this.redireccionar.bind(this);
    this.reiniciar = this.reiniciar.bind(this);
    this.renderNumGrabaciones = this.renderNumGrabaciones.bind(this);
    this.renderGrabarFrase = this.renderGrabarFrase.bind(this);
    this.renderDecision = this.renderDecision.bind(this);
  }

  componentDidMount() {
    this.iniciarDatos();
  }

  componentWillUnmount() {
    this.props.hideInfoPanel();
  }

  onError(msg) {
    this.redireccionar(msg);
  }

  onSuccess(blob, audioDuration,) {
    // Crea un formulario
    const data1 = new FormData();
    // Adjunta el audio
    data1.append('file', blob, 'audio');
    // Adjunta el id de la frase
    data1.append('id', this.state.frase['_id']);
    // Adjunta el dni del usuario
    data1.append('autor', this.state.parametrosOmni.codusuario);
    data1.append('duration', audioDuration);

    AudioAPI.sendAudio(data1).then((response1) => {
      const data2 = {
        idUsuario: this.state.parametrosOmni.codusuario,
        fecha: this.state.parametrosOmni.time,
        url: this.state.parametrosOmni.urlredirect
      };
      AudioAPI.saveTransaction(data2).then((response2) => {
        const { numGrabacion } = this.state;
        const nuevoNumGrabacion = numGrabacion + 1;
        const mostrarBotonGrabar = (nuevoNumGrabacion < maxNumGrabaciones);
        const mostrarBotonRedireccion = (nuevoNumGrabacion >= minNumGrabaciones);
        if (response2.status === 201) {
          this.setState({
            mostrarBotonGrabar,
            mostrarBotonRedireccion,
            mensaje: '',
            frase: '',
            numGrabacion: nuevoNumGrabacion,
            haGrabado: true
          });
        } else {
          this.redireccionar('Error al almacenar la transacción');
        }
      }).catch(err => this.redireccionar('Error al almacenar la transacción', err));
    }).catch(err => this.redireccionar('Error al almacenar el audio', err));
  }

  redireccionar(msg, error) {
    const { parametrosOmni } = this.state;
    if (error)console.error(error);
    console.log(msg);
    window.location = parametrosOmni.urlredirect;
  }

  recuperarFrase() {
    // Recupere frase
    AudioAPI.getRandPhrase()
      .then((response) => {
        this.setState({
          frase: response,
          mensaje: 'Pronuncia la siguiente frase'
        }, this.recuperarInfoAudios());
      }).catch((e) => {
        this.setState({
          mensaje: 'Error al recuperar la frase del servidor'
        }, () => { this.redireccionar('Error al recuperar la frase del servidor', e); });
      });
  }

  recuperarInfoAudios() {
    const { parametrosOmni } = this.state;
    const { showInfoPanel, setInfoPanel } = this.props;
    UsuarioAPI.getInfoAudios(parametrosOmni.codusuario).then((response) => {
      if (response.status === 0) {
        showInfoPanel();
        setInfoPanel(response.info);
      }
    });
  }

  reiniciar() {
    this.iniciarDatos();
  }

  iniciarDatos() {
    this.setState({
      mostrarBotonRedireccion: false,
      mostrarBotonGrabar: false
    }, () => this.validarParametros());
    /*   this.recuperarFrase();
    if (canSend) this.recuperarInfoAudios(); */
  }

  validarParametros() {
    const { location } = this.props;
    // Parametros del omni
    const params = queryString.parse(location.search);
    if (
      typeof params.time !== 'undefined'
      && typeof params.codusuario !== 'undefined'
      && typeof params.urlredirect !== 'undefined'
    ) {
      let isLogin = true;
      if (typeof params.islogin !== 'undefined') {
        isLogin = !!((params.islogin === 1 || params.islogin === '1'));
      }
      this.setState(
        {
          parametrosOmni: {
            time: params.time,
            codusuario: params.codusuario,
            urlredirect: params.urlredirect,
            isLogin
          }
        },
        () => {
          this.recuperarInfoAudios();
          this.validarUsuario();
        }
      );
    } else {
      this.setState({
        mensaje: 'Faltan parámetros'
      });
    }
  }

  validarUsuario() {
    const { parametrosOmni } = this.state;
    // El antiguo tiempo registrado
    AudioAPI.checkUser(parametrosOmni.codusuario)
      .then((response) => {
        const code = response.status;
        const { haGrabado, isLogin } = this.state;
        if (code === 0) { // Ok
          const newDate = parseInt(parametrosOmni.time, 10);
          const oldDate = parseInt(response.usuario.fecha, 10);

          // Si la diferencia es mayor o igual a 1 hora
          if (!isLogin || haGrabado || (newDate - oldDate >= 1000 * 60 * timeToRedirect)) {
            if (response.usuario.numAudiosHoy < maxNumGrabaciones) {
              this.setState({
                numGrabacion: response.usuario.numAudiosHoy
              }, this.recuperarFrase());
            } else {
              this.setState({
                numGrabacion: response.usuario.numAudiosHoy,
                mostrarBotonRedireccion: true,
                mensaje: 'Felicitaciones: ha terminado de grabar por hoy.'
              });
            }
          } else {
            // Redirige a omni
            this.redireccionar('Sesión activa');
          }
        } else if (code === 1) { // Error en los parámetros
          this.setState({
            numGrabacion: 0,
            mensaje: 'Falta parámetro usuario'
          });
        } else if (code === 2) { // No existía -> nuevo
          this.setState({
            numGrabacion: 0
          }, this.recuperarFrase());
        }
      }).catch((e) => {
        this.setState({
          mensaje: 'Error al validar usuario',
          mostrarBotonRedireccion: true
        });
      });
  }

  static renderTitulo() {
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>Calibra tu voz</h2>
      </div>
    );
  }

  renderNumGrabaciones() {
    const { numGrabacion } = this.state;
    return (
      <div>
        {
          numGrabacion !== null
            ? (
              <h4>
                Grabaciones hoy: {numGrabacion}/{maxNumGrabaciones}
              </h4>
            )
            : null
        }
      </div>
    );
  }

  renderGrabarFrase() {
    const { frase } = this.state;
    return (
      <div>
        {frase.length !== 0 ? (
          <div>
            <blockquote>
              <h5>{frase.texto}</h5>
            </blockquote>
            <hr />
            <div>
              <RecorderContainer
                onError={this.onError}
                onAudio={this.onSuccess}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderDecision() {
    const { mostrarBotonGrabar, mostrarBotonRedireccion } = this.state;
    return (
      <Row>
        <Col s={1} />
        <Col s={4}>
          {mostrarBotonGrabar ? (
            <Button waves="orange" onClick={this.iniciarDatos}>
              Grabar otro audio
              <Icon right>cached</Icon>
            </Button>
          ):null}
        </Col>
        <Col s={2} />
        <Col s={4}>
          {mostrarBotonRedireccion ? (
            <Button waves="orange" onClick={this.redireccionar}>
              Continuar Omni
              <Icon right>forward</Icon>
            </Button>
          ) : null}

        </Col>
        <Col s={1} />
      </Row>
    );
  }

  render() {
    const { mensaje, mostrarBotonGrabar, mostrarBotonRedireccion } = this.state;
    return (
      <div>
        <div>
          {Audio.renderTitulo()}
          {this.renderNumGrabaciones()}
          <h4>{mensaje}</h4>

          { (mostrarBotonGrabar || mostrarBotonRedireccion)
            ? this.renderDecision()
            : this.renderGrabarFrase()
          }
        </div>
      </div>
    );
  }
}

export default Audio;
