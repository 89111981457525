/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Icon,
  Button,
  Preloader
} from 'react-materialize';

export default class Archivo extends Component {
  static convertirTamano(tamano) {
    const factores = ['B', 'KB', 'MB', 'GB', 'TB'];
    let factor = 0;
    let nuevoTamano = tamano;
    while (nuevoTamano >= 1000) {
      nuevoTamano /= 1000;
      factor += 1;
    }
    return `${nuevoTamano.toFixed(1)} ${factores[factor]}`;
  }

  static renderArchivoInvalido() {
    return (
      (
        <div>
          <Row>
            <Col s={4} />
            <Col
              s={4}
              style={{
                color: 'white',
                backgroundColor: 'red',
                textAlign: 'center'
              }}
            >
              <Row>
                <Icon center>
                  error
                </Icon>
              </Row>
              <Row>Archivo inválido</Row>
            </Col>
            <Col s={4} />
          </Row>
        </div>
      )
    );
  }

  static renderArchivoEnviado() {
    return (
      (
        <div>
          <Row>
            <Col s={4} />
            <Col
              s={4}
              style={{
                color: 'white',
                backgroundColor: 'green',
                textAlign: 'center'
              }}
            >
              <Row>
                <Icon center>
                  check
                </Icon>
              </Row>
              <Row>Archivo enviado</Row>
            </Col>
            <Col s={4} />
          </Row>
        </div>
      )
    );
  }

  static renderArchivoError() {
    return (
      (
        <div>
          <Row>
            <Col s={4} />
            <Col
              s={4}
              style={{
                color: 'white',
                backgroundColor: 'red',
                textAlign: 'center'
              }}
            >
              <Row>
                <Icon center>
                  cancel
                </Icon>
              </Row>
              <Row>Error al enviar archivo</Row>
            </Col>
            <Col s={4} />
          </Row>
        </div>
      )
    );
  }

  static renderCargando() {
    return (
      <Col
        s={12}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Preloader flashing />
      </Col>
    );
  }

  constructor(props) {
    super(props);
    this.renderIcono = this.renderIcono.bind(this);
    this.renderInformacion = this.renderInformacion.bind(this);
    this.renderArchivoPendiente = this.renderArchivoPendiente.bind(this);
    this.renderArchivo = this.renderArchivo.bind(this);
  }

  renderIcono() {
    const { estado } = this.props;
    let color = '#60beca';
    if (estado === 'formato-invalido') {
      color = 'red';
    } else if (estado === 'pendiente') {
      color = '#60beca';
    } else if (estado === 'enviado') {
      color = 'green';
    } else if (estado === 'error-enviar') {
      color = 'red';
    } else if (estado === 'cargando') {
      color = 'purple';
    }
    return (
      <Row style={{ color }}>
        <Icon left>
            whatshot
        </Icon>
      </Row>
    );
  }

  renderInformacion() {
    const { archivo } = this.props;
    return (
      <div>
        <Row>
          <span style={{ color: '#01a1dd' }}>Nombre: </span>
          <span>{archivo.path}</span>
        </Row>
        <Row>
          <span style={{ color: '#01a1dd' }}>MIME: </span>
          <span>{archivo.type}</span>
        </Row>
        <Row>
          <span style={{ color: '#01a1dd' }}>Tamaño: </span>
          <span>{Archivo.convertirTamano(archivo.size)}</span>
        </Row>
      </div>
    );
  }

  renderArchivoPendiente() {
    const { archivo, eliminarArchivo, enviarArchivo } = this.props;
    return (
      <div>
        <Row>
          <Col s={12}>
            <audio
              controls
              style={{ width: '100%' }}
              src={URL.createObjectURL(archivo)}
            />
          </Col>
        </Row>
        <Row>
          <Col s={1} />
          <Col s={4}>
            <Button onClick={() => eliminarArchivo(archivo)}>
              Eliminar
            </Button>
          </Col>
          <Col s={2} />
          <Col s={4}>
            <Button onClick={() => enviarArchivo(archivo)}>
              Enviar
            </Button>
          </Col>
          <Col s={1} />
        </Row>
      </div>
    );
  }

  renderArchivo() {
    const { estado } = this.props;
    let componente = null;
    if (estado === 'formato-invalido') {
      componente = Archivo.renderArchivoInvalido();
    } else if (estado === 'pendiente') {
      componente = this.renderArchivoPendiente();
    } else if (estado === 'enviado') {
      componente = Archivo.renderArchivoEnviado();
    } else if (estado === 'error-enviar') {
      componente = Archivo.renderArchivoError();
    } else if (estado === 'cargando') {
      componente = Archivo.renderCargando();
    }
    return componente;
  }

  render() {
    return (
      <div
        style={{
          borderBottomStyle: 'dotted',
          marginBottom: '2vh'
        }}
      >
        {this.renderIcono()}
        {this.renderInformacion()}
        {this.renderArchivo()}
      </div>
    );
  }
}
