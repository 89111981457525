import React, { Component } from 'react'

class Home extends Component {
    render() {        
        return(
            <div style={{textAlign:'center'}}>
                <h3>Alpha Corpus</h3>

                <h5>Ambiente: {process.env.REACT_APP_NODE_ENV}</h5>

                <p>Iniciativa corporativa para recolectar audios propios del negocio.</p>            
            </div>
        )
    }
}

export default Home;