import { INSTANCE, serverURL } from "../";

export class RankingAPI {

  static getDataRanking() {
    return INSTANCE.get("/api/usuarios/ranking")
          .then(response => response.data);
  }

  static getMonthDataRanking(year, month){
    return INSTANCE.get(`/api/usuarios/ranking/${year}/${month}`)
      .then(response => response.data)
  }

  static getInform(id, year, month, pag){
    return INSTANCE.get(`/api/usuarios/informe/${id}/${year}/${month}/${pag}`)
    .then((response) => {
      response.data.serverURL = serverURL;
      return response.data;
    });
  }
  
}