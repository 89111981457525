/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';


import { Row, Col } from 'react-materialize';

import './boxSelector.css';

const animatedComponents = makeAnimated();

const BoxSelector = ({
  data, object = false, onChange, value
}) => {
  const onChangeHandler = (changed) => {
    if (changed === null) {
      onChange([]);
    } else {
      onChange(changed.map(item => (item.value)));
    }
  };

  let options = false;

  if (typeof object === 'object' && object.id !== undefined && object.text !== undefined) {
    options = data.map(item => ({ value: item[object.id], label: item[object.text] }));
  } else if (typeof data.slice().pop() === 'string') {
    options = data.map(item => ({ value: item, label: item }));
  }

  const currentValue = value.reduce((partial, current) => {
    const findCurrent = options.find(item => item.value === current);
    if (findCurrent !== undefined) {
      partial.push(findCurrent);
    }
    return partial;
  }, []);

  if (options === false) {
    return (
      <Row>
       Error en data
      </Row>
    );
  }

  return (
    <Row>
      <Col s={12} className="boxSelector">
        <Select
          value={currentValue}
          classNamePrefix="boxSelector"
          components={animatedComponents}
          options={options}
          closeMenuOnSelect={false}
          isMulti
          onChange={onChangeHandler}
        />
      </Col>
    </Row>
  );
};

BoxSelector.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  object: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    PropTypes.bool
  ]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

BoxSelector.defaultProps = {
  object: false
};

export default BoxSelector;
