import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import BurguerButton from './BurguerButton'


const rutaCronos = process.env.REACT_APP_RUTA_CRONOS || '/cronos';
const rutaAudio = process.env.REACT_APP_RUTA_AUDIO || '/audio';
// const rutaAudioBR = process.env.REACT_APP_RUTA_AUDIO_BR || '/audio-br';
const rutaEvaluar = process.env.REACT_APP_RUTA_EVALUAR || '/evaluar';
const rutaLocutor = process.env.REACT_APP_RUTA_LOCUTOR || '/44B1229C573B9E7D93D952599F7FAD40';
// const rutaLocutorBR = process.env.REACT_APP_RUTA_LOCUTOR_BR || '/locutor-br';
const rutaRanking = process.env.REACT_APP_RUTA_RANKING || '/ranking';
const rutaHistorico = process.env.REACT_APP_RUTA_HISTORICO || '/historico';
const rutaPlataforma = process.env.REACT_APP_RUTA_PLATAFORMA || '/plataforma';
// const rutaPlataformaBR = process.env.REACT_APP_RUTA_PLATAFORMA_BR || '/plataforma-br';
const rutaInforme = process.env.REACT_APP_RUTA_INFORME || '/informe';
const rutaSearchAudios = '/new';
const rutaDeleteAudio = '/DeleteFrase';

function Navbar() {
    const[clicked, setClicked]=useState(false)
    const handleClick = () =>{
        //cuando está true lo pasa a false y viceversea
        setClicked (!clicked)
    }
  return (
    <>
        <NavContainer>
           <div className='titulo'>
            <h4>Alpha  <span>Corpus</span> </h4><img
              src='/assets/ico_copy.png'
              alt='logo'
              style={{ height: '100px' }}
              />
            </div>
            <div className= 'links'/*{`links ${clicked ? 'active' : ''}`}*/>
               
            {/* <Link onClick={handleClick} id='navcronos' to={rutaCronos}>Cronos</Link> */}
            {/* <Link onClick={handleClick} id='navaudios2 ' to={rutaAudio}>Audio</Link> */}
            <Link /*onClick={handleClick}*/ id='navevaluar' to={rutaEvaluar}>Evaluar</Link>
            <Link /*onClick={handleClick}*/ id='navlocutor' to={rutaLocutor}>Locutor</Link>
            <Link /*onClick={handleClick}*/ id='navranking' to={rutaRanking}>Ranking</Link>
            <Link /*onClick={handleClick}*/ id='navhistorico' to={rutaHistorico}>Histórico</Link>
            {/* <Link onClick={handleClick} id='navplataforma' to={rutaPlataforma}>Plataforma</Link> */}
            {/* <Link onClick={handleClick} id='navinforme' to={rutaInforme}>Informe</Link> */}
            {/* <Link onClick={handleClick} id='navaudios' to={rutaSearchAudios}>Buscar Audios</Link> */}
            <Link /*onClick={handleClick}*/ id='navdelete' to={rutaDeleteAudio}>Eliminar Frase</Link>
            </div>
            {/* <div className='burger'>
            <BurguerButton clicked={clicked} handleClick={handleClick} />
            <Bgdiv className={`links ${clicked ? 'active' : ''}`}></Bgdiv>
            </div> */}
            
        </NavContainer>
    </>
  )
}

export default Navbar
const NavContainer = styled.nav`
    h2{
        color: white;
        font-weight:400;
        span{
            font-weight:bold;
        }
    }
    padding: 4rem;
    background-color:#01a1dd;
    display:flex;
    align-items:center;
    justify-content: space-between;
    a{
        color: white;
        text-decoration: none;
        margin-right: 1rem;
    }
    .links{
        position: absolute;
        top: -700px;
        left: -2000px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        transition: all .5s ease;
        a{
          color: white;
          font-size: 2rem;
          display: block;
        }
        @media(min-width: 468px){
          position: initial;
          margin: 0;
          a{
            font-size: 1rem;
            color: white;
            display: inline;
          }
          display: block;
        } 
      }
      .links.active{
        width: 100%;
        display: block;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 0%;
        left: 0;
        right: 0;
        text-align: center;
        a{
          font-size: 2rem;
          margin-top: 1rem;
          color: white;
        }
      }
    .burger{
        @media(min-width:768px){
            display: none;
        }
    }
    .titulo{
      display: flex;
   align-items: center;
    }
`
const Bgdiv = styled.div`
background-color: black;
position: absolute;
top: -1000px;
left: -1000px;
width: 100%;
height: 100%;
z-index: 100;
transition: all .6s ease ;

&.active{
    border-radius: 0 0 100% 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

`