/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable prefer-destructuring */
/* eslint-disable one-var */
/* eslint-disable arrow-body-style */
/* eslint-disable spaced-comment */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable linebreak-style */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-equals-spacing */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
// 7 Marzo / 2019
// John Tapias
// Componente para mostrar el ranking de usuarios
import React, { Component } from 'react';
import {
  Table, Row, Col, Preloader, Button, Icon, Modal, Pagination
} from 'react-materialize';

import Picker from 'react-month-picker';

import { RankingAPI } from '../../services/api/ranking-api';

import 'react-month-picker/css/month-picker.css';
import './ranking.css';

class RenderCol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      url: this.props.audioURL
    };
    this.renderCol = this.renderCol.bind(this);
    this.colButton = this.colButton.bind(this);
    this.audioTag = this.audioTag.bind(this);
  }

  audioTag() {
    const { url } = this.state;
    return (
      <audio controls style={{ width: '100%' }} src={url} />
    );
  }

  colButton() {
    return (
      <Button style={{ display: 'center' }} onClick={() => { this.setState({ flag: 1 }); }}>
        <Icon center>play_circle_filled</Icon>
      </Button>
    );
  }

  renderCol() {
    const { flag } = this.state;
    if (flag !== 0) {
      return this.audioTag();
    }
      return this.colButton();
  }

  render() {
    return this.renderCol();
  }
}

class Ranking extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    this.state = {
      users: [],
      sortUsers: [],
      filterLocutor: true,
      filterUnrank: true,
      showDate: false,
      year,
      month,
      loading: false,
      error: false,
      loadModal: false,
      pag: 1,
      detailStatus: 0,
      totalPags: 1,
      userData: null,
      id: null,
      serverURL: ''
    };
    this.langPicker = {
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    };
    this.yearsPick = {
      min: { year: 2018, month: 12 }, max: { year, month: month + 1 }
    };
    this.setUnrankFilter = this.setUnrankFilter.bind(this);
    this.setLocutorFilter = this.setLocutorFilter.bind(this);
    this.toogleLocutorFilter = this.toogleLocutorFilter.bind(this);
    this.toogleUnrankFilter = this.toogleUnrankFilter.bind(this);
    this.todosFilter = this.todosFilter.bind(this);
    this.monthRankingData = this.monthRankingData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.consultData = this.consultData.bind(this);
    this.detailUser = this.detailUser.bind(this);
    this.userData = this.userData.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
  }

  componentDidMount() {
    this.iniciarDatos();
  }

  onChange(year, month) {
    this.setState({
      year,
      month: month - 1
    }, this.consultData);
  }

  setLocutorFilter() {
    const temp = this.state.sortUsers.filter(user => !user.idUsuario.includes('locutor'));
    this.setState({
      sortUsers: temp
    }, () => {
      if (this.state.filterUnrank) {
        this.setUnrankFilter();
      } else {
        this.dataSort();
      }
    });
  }

  setUnrankFilter() {
    const temp = this.state.sortUsers.filter(user => user.rank !== 0 && user.rank !== null);
    this.setState({
      sortUsers: temp
    }, () => this.dataSort());
  }

  iniciarDatos() {
    this.setState({ loading: true });
    RankingAPI.getDataRanking().then((response) => {
      if (response.status === 0) {
        const filter = response.usuarios.filter(user => typeof (user.idUsuario) !== 'undefined');
        this.setState({
          users: filter,
          sortUsers: filter,
          loading: false
        }, () => {
          this.filtrar();
        });
      } else {
        this.setState({ error: response.mensaje });
      }
    });
  }

  filtrar() {
    this.setState({ error: false });
    if (this.state.filterLocutor) {
      this.setState({
        sortUsers: this.state.users
      }, () => this.setLocutorFilter());
    } else if (this.state.filterUnrank) {
      this.setState({
        sortUsers: this.state.users
      }, () => this.setUnrankFilter());
    } else if (!this.state.filterLocutor && !this.state.filterUnrank) {
      this.setState({
        sortUsers: this.state.users
      }, () => this.dataSort());
    }
  }

  toogleUnrankFilter() {
    const toogle = !this.state.filterUnrank;
    this.setState({
      filterUnrank: toogle
    }, () => this.filtrar());
  }

  toogleLocutorFilter() {
    const toogle = !this.state.filterLocutor;
    this.setState({
      filterLocutor: toogle
    }, () => this.filtrar());
  }

  todosFilter() {
    this.setState({
      filterLocutor: false,
      filterUnrank: false
    }, () => this.filtrar());
  }

  dataSort() {
    const sortFilter = this.state.sortUsers.sort((a, b) => b.rank - a.rank);
    sortFilter.map((usu, posUsu) => usu.pos = posUsu + 1);
    this.setState({
      sortUsers: sortFilter
    });
  }

  monthRankingData() {
    this.setState({ loading: true, error: false });
    RankingAPI.getMonthDataRanking(this.state.year, this.state.month).then((response) => {
      if (response.status === 0 && response.usuarios !== null) {
        const { motitoresVirtuales } = response.usuarios;
        const filter = motitoresVirtuales.filter(user => typeof (user.idUsuario) !== 'undefined');
        this.setState({
          users: filter,
          sortUsers: filter,
          loading: false
        }, this.filtrar);
      } else if (response.status === 0 && response.usuarios === null) {
        this.setState({
          error: 'No se ha sincronizado la data para el mes seleccionado',
          loading: false
        });
      } else {
        this.setState({
          error: response.mensaje,
          loading: false
        });
      }
    });
  }

  consultData() {
    const now = new Date();
    if (now.getMonth() === this.state.month && now.getFullYear() === this.state.year) {
      this.setState({
        showDate: false
      }, this.iniciarDatos);
    } else {
      this.setState({
        showDate: true
      }, this.monthRankingData);
    }
  }

  userData() {
    this.setState({ loadModal: true });
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const { pag, id } = this.state;
    RankingAPI.getInform(id, year, month, pag).then((response) => {
      if (response.status === 0 && response.paginas !== null && response.informacionDetallada !== null) {
        this.setState({
          loadModal: false,
          totalPags: response.paginas.total,
          serverURL: response.serverURL,
          userData: response.informacionDetallada,
          detailStatus: 0
        }, this.renderDetails);
      } else {
        this.setState({
          loadModal: false,
          detailStatus: 1
        }, this.renderDetails);
      }
    });
  }

  detailUser() {
    if (this.state.loadModal) {
      return this.renderCargando();
    }
    return this.renderDetails();
  }

  renderDetails() {
    if (this.state.detailStatus === 0 && this.state.userData !== null) {
      return (
        <div>
          <div>
            <Pagination onSelect={(e) => { this.setState({ pag: e }, this.userData); }} activePage={this.state.pag} items={this.state.totalPags} maxButtons={10} />
          </div>
          <Row>
            <div>
              <Table>
                <thead>
                  <tr>
                    <th> F </th>
                    <th> Texto </th>
                    <th> Audio </th>
                    <th> Observacion </th>
                    <th> Eval </th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.userData.map((it, key) => {
                    const { fecha, evaluaciones, nombre } = it;
                    const { serverURL } = this.state;
                    const dateSplit = fecha.substr(0, 10);
                    const audioURL = `${serverURL}/api/audios/archivo/nombre/${nombre}`;
                    let txtObservacion = 'no disponible';
                    let valoracion = 'N/D';
                    if (evaluaciones.length > 0) {
                      txtObservacion = it.evaluaciones[0].txtObservacion;
                      valoracion = it.evaluaciones[0].valoracion;
                    }
                    return (
                      <tr key={key}>
                        <td width={100}>{dateSplit}</td>
                        <td width={100}>{it.texto}</td>
                        <td width={300}>
                          <RenderCol audioURL={audioURL} />
                        </td>
                        <td width={100}>{txtObservacion}</td>
                        <td width={100}>{valoracion}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Row>
          <div>
            <Pagination onSelect={(e) => { this.setState({ pag: e }, this.userData); }} activePage={this.state.pag} items={this.state.totalPags} maxButtons={10} />
          </div>
        </div>
      );
    }
    return (<p>No se han podido sincronizar datos</p>);
  }

  renderTablaRanking() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div>
        <Row>
          <Col s={3} className="center">
            <Button
              className={this.state.filterUnrank ? 'red' : 'green'}
              onClick={this.toogleUnrankFilter}
            >
              Sin Ranking
              <Icon left>remove_red_eye</Icon>
            </Button>
          </Col>
          <Col s={3} className="center">
            <Button
              className={this.state.filterLocutor ? 'red' : 'green'}
              onClick={this.toogleLocutorFilter}
            >
              Locutores
              <Icon left>remove_red_eye</Icon>
            </Button>
          </Col>
          <Col s={3} className="center">
            <Button onClick={this.todosFilter}>
              Todos
              <Icon left>remove_red_eye</Icon>
            </Button>
          </Col>
          <Col s={3} className="center" style={{ borderLeft: '1px solid grey' }}>
            <Picker
              ref="pickAMonth"
              lang={this.langPicker}
              years={this.yearsPick}
              value={{ year: this.state.year, month: this.state.month + 1 }}
              onChange={this.onChange}
            >
              <Button onClick={() => { this.refs.pickAMonth.show(); }}>
                Seleccionar Mes
                <Icon left>remove_red_eye</Icon>
              </Button>
            </Picker>
          </Col>
        </Row>
        <Row>
          <Col s={12}>
            <h5>
              {
                this.state.showDate === false
                  ? 'Mes actual'
                  : `${String(this.state.month + 1).padStart(2, '0')}/${this.state.year}`
              }
            </h5>
            <hr />
          </Col>
        </Row>
        {
          this.state.error !== false
            ? (
              <Row>
                <Col s={12} className="center">
                  {this.state.error}
                </Col>
              </Row>
            )
            : (
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th data-field="id"># Ranking</th>
                      <th>Usuario</th>
                      <th>Cartera</th>
                      <th>Calificación Promedio</th>
                      <th>
                        Grabaciones Calificadas /
                        <br />
                        Grabaciones Hechas
                      </th>
                      <th>Detalles</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.sortUsers.map((item, key) => {
                      const { business = null, area = null } = item;
                      let sede = 'Servex';
                      if (business === null && area !== null) {
                        sede = area;
                      } else if (area === null && business !== null) {
                        sede = business;
                      }
                      return (
                        <tr key={key}>
                          <td>{item.rank === null || item.rank === 0 ? 'Pendiente' : item.pos}</td>
                          <td>{item.idUsuario}</td>
                          <td>{sede}</td>
                          <td>{item.calificacion === null ? 'Pendiente' : item.calificacion.toPrecision(3)}</td>
                          <td>{item.numAudiosCalificados} / {item.numAudios}</td>
                          <td>
                            <Modal
                              key={item.idUsuario}
                              header="Detalles de asesor"
                              className="modalWidth"
                              options={{
                                onOpenStart: () => { this.setState({ id: item.idUsuario, pag: 1 }, this.userData); }
                              }}
                              trigger={
                                <Button>
                                  Ver
                                </Button>
                              }
                            >
                              {this.detailUser()}
                            </Modal>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            )
        }
      </div>

    );
  }

  renderCargando() {
    return (
      <div>
        <Row>
          <Col s={12} className="center">
            <Preloader flashing />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return this.renderCargando();
    }
    return this.renderTablaRanking();
  }
}

export default Ranking;
