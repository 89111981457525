/* eslint-disable import/prefer-default-export */
import { INSTANCE, serverURL } from '../';


const getFilterData = (filter) => {
  const { 
      typeDate = '', 
      from = undefined,  
      to = undefined, 
      tags = [], 
    } = filter;

    const data = {};

    if(tags.length > 0) {
      data.etiquetas = tags
    }

    if(typeDate === 'range' && from !== undefined && to !== undefined) {
      data.date = {
        gte: from,
        lte: to,
      }
    }else if(typeDate !== '' && (from !== undefined || to !== undefined)) {
      data.date = { 
        [typeDate]: typeDate === 'gte' ? from : to 
      }
    }
    return data;
}


export class EvaluarAPI {
  static getRandPhrase(filter = {}) {
    return INSTANCE.post('/api/evaluaciones/aleatoria', getFilterData(filter))
      .then((response) => {
        response.data.serverURL = serverURL;
        return response;
      });
  }

  static countRandPhrase(filter = {}) {
    return INSTANCE.post('/api/evaluaciones/aleatoria/count', getFilterData(filter))
      .then((response) => {
        return response.data;
      });
  }
}
