import { INSTANCE, serverURL } from "../";

export class DeleteAPI {

  static frasesSinAudio(dialog, autor, mes, anio) {
    return INSTANCE.post('/api/deleteFrases/delete/',
      {
        dialog,
        autor,
        mes,
        anio
      })
      .then(response => response.data);
  }

  // /updateDelete/:id
  static updateFrase(id){
    return INSTANCE.put(`/api/deleteFrases/updateDelete/${id}`)
    .then(response => response.data);
  }
}

