import { INSTANCE } from "../";

export class AudioAPI {

  static sendAudio(data) {
    return INSTANCE.post("/api/audios", data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`
      },
      // milliseconds antes de petición times out
      timeout: process.env.REACT_APP_UPLOAD_AUDIO_TIMEOUT
    }).then(response => response.data);
  }

  static saveTransaction(data) {
    return INSTANCE.post('/api/usuarios/', data)
      .then( (response) =>  response );
  }

  static checkUser(data) {
    return INSTANCE.get(
      '/api/usuarios/usuario/id/' + data
    ).then( response => response.data);
  }

  static getRandPhrase() {
    return INSTANCE.get('/api/frases/aleatoria')
    .then(response => response.data);
  }

  static getRandPhraseBR() {
    return INSTANCE.get('/api/frases/br/aleatoria')
    .then(response => response.data);
  }

}
