import React, { Component } from 'react';
import axios from 'axios';

import { Textarea } from 'react-materialize';
const serverProtocol = process.env.REACT_APP_HOST_PROTOCOL || 'http'
const serverHost = process.env.REACT_APP_HOST_SERVER || '127.0.0.1'
const serverPort = process.env.REACT_APP_PORT_SERVER || '3003'

var serverURL = ""
if (serverProtocol === "https") {
    serverURL = `${serverProtocol}://${serverHost}`
} else {
    //Diferente de prod
    serverURL = `${serverProtocol}://${serverHost}:${serverPort}`
}

const instance = axios.create({
    baseURL: serverURL,
    headers: { 'Content-Type': 'application/json' }
})


export class Cronos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audiosTotales: null,
      audioURL: null,
      numAudiosTotales:0,
      audiosSinDuracion:null,
      numAudiosSinDuracion:0,
      audiosConInfinito:null,
      numAudiosConInfinito:0,
      audiosSinInfinito:null,
      numAudiosSinInfinito:0,
      numAudiosBorrados:0,
      numAudiosSinBorrar: 0,
      numAudiosConDuracion: 0,
      tag: [],
    }
    this.audioRef = React.createRef();
    this.recorrerAudiosSinDuracion = this.recorrerAudiosSinDuracion.bind(this);
    this.recorrerAudiosConInfinito = this.recorrerAudiosConInfinito.bind(this);
    this.settag = this.settag.bind(this);
    this.saveStaticontag = this.saveStaticontag.bind(this);
    this.saveInCacheStatics = this.saveInCacheStatics.bind(this);
  }

  componentDidMount() {
    console.log('-----');
    instance.get('/api/audios').then( (res) => {
      console.log(res.data);
      console.log('-----');

      let audiosTotales = res.data;
      let numAudiosTotales = audiosTotales.length;

      let audiosBorrados = audiosTotales.filter( (a) => a.borrado===true );
      let numAudiosBorrados = audiosBorrados.length;

      let audiosSinBorrar = audiosTotales.filter( (a) => a.borrado===false );
      let numAudiosSinBorrar = audiosSinBorrar.length;

      let audiosSinDuracion = audiosSinBorrar.filter(a => typeof a.duracion === 'undefined');
      let numAudiosSinDuracion = audiosSinDuracion.length;

      let audiosConDuracion = audiosSinBorrar.filter(a => typeof a.duracion !== 'undefined');
      let numAudiosConDuracion = audiosConDuracion.length;

      let audiosConInfinito = audiosConDuracion.filter(a => a.duracion === null);
      let numAudiosConInfinito = audiosConInfinito.length;

      let audiosSinInfinito = audiosConDuracion.filter(a => a.duracion !== null);
      let numAudiosSinInfinito = audiosSinInfinito.length;

      this.setState({
        audiosTotales: audiosTotales,
        numAudiosTotales: numAudiosTotales,
        audiosSinDuracion: audiosSinDuracion,
        audiosConInfinito: audiosConInfinito,
        audiosSinInfinito: audiosSinInfinito,
        numAudiosSinDuracion: numAudiosSinDuracion,
        numAudiosBorrados: numAudiosBorrados,
        numAudiosSinBorrar: numAudiosSinBorrar,
        numAudiosConDuracion: numAudiosConDuracion,
        numAudiosConInfinito: numAudiosConInfinito,
        numAudiosSinInfinito: numAudiosSinInfinito,
      }, ()=>console.log(this.state));

    });
  }

  recorrerAudiosSinDuracion(e){
    for (let audio of this.state.audiosSinDuracion) {
      let audioURL = `${serverURL}/api/audios/archivo/nombre/${audio.nombre}`;
      //Montar audio
      let tempAudio = document.createElement('audio');

      tempAudio.addEventListener('loadedmetadata',()=>{
        //Obtener duracion
        let audioDuration = tempAudio.duration;
        console.log(`audio -> ${audio.nombre}   duracion -> ${audioDuration}`)

        instance.post('/api/audios/duration',
        {
          audioID: audio['_id'],
          duration: audioDuration,
          erased:false
        },(respuesta)=>{
          console.log('ok respuesta')
          console.log(respuesta)
        });
      });

      tempAudio.addEventListener('error', ()=>{
        //Borrar logicamente el audio
        console.log(`audio -> ${audio.nombre}   ERROR`);
        instance.post('/api/audios/duration',
        {
          audioID: audio['_id'],
          duration: 0,
          erased:true
        },(respuesta)=>{
          console.log('error respuesta')
          console.log(respuesta)
        });
      });

      tempAudio.src = audioURL;
    }
    console.log(this.state);
  }

  recorrerAudiosConInfinito(e){
    console.log('recorrerAudiosConInfinito');
    for (let audio of this.state.audiosConInfinito) {
      let audioURL = `${serverURL}/api/audios/archivo/nombre/${audio.nombre}`;

      //Montar audio
      let tempAudio = document.createElement('audio');

      tempAudio.addEventListener('loadedmetadata',()=>{
        //Obtener duracion
        let audioDuration = tempAudio.duration;
        console.log(`audio -> ${audio.nombre}   duracion -> ${audioDuration}`)

        instance.post('/api/audios/duration',
        {
          audioID: audio['_id'],
          duration: audioDuration,
          erased:false
        },(respuesta)=>{
          console.log('ok respuesta')
          console.log(respuesta)
        });
      });

      tempAudio.addEventListener('error', ()=>{
        console.log(`Error en audio['_id']: ${audio['_id']}`)
      });

      tempAudio.src = audioURL;
    }
    console.log(this.state);
  }

  establecerPromedioTodos(e){
    let usuariosConError = [] ;
    instance.get('/api/usuarios')
    .then( (res) => {
      let usuarios = res.data.usuarios;
      usuarios.forEach((usuario) => {
        console.log(`${usuario.idUsuario} -> Start`)
        instance.get(`/api/cronos/establecer-promedio-actual/${usuario.idUsuario}`).then((resultado)=>{
          if (resultado) console.log(`${usuario.idUsuario} -> Ok`);
        }).catch((err) => {
          console.log(`${usuario.idUsuario} -> Error`);
          usuariosConError.push(usuario.idUsuario);
        })
      })
    }).then(()=>console.log(usuariosConError));
  }

  establecerEtiquetasPrimerasFrases(e) {
    instance.get('/api/cronos/set-etiquetas-en-primeras-frases')
    .then( (res) => {
      console.log(res.data);
    });
  }

  establecerEtiquetasAudios(e) {
    instance.get('/api/cronos/set-etiquetas-en-audios')
    .then( (res) => {
      console.log(res.data);
    });
  }

  settag(e) {
    const { value } = e.target;
    this.setState({
      tag: value
    });
  }

  saveStaticontag() {
    instance.post('/api/cronos/set-static-tag', { tag: this.state.tag })
    .then( (res) => {
      const { data } = res;
      const { success, mensaje = '', error = '' } = data;
      if(success === false){
        alert(`ERROR: ${error}`);
      }else{
        alert(`CORRECTO: ${mensaje}`)
      }
    });
  }

  saveInCacheStatics() {
    instance.get('/api/cronos/save-static-cache')
    .then( (res) => {
      const { data } = res;
      const { success, mensaje = '', error = '' } = data;
      if(success === false){
        alert(`ERROR: ${error}`);
      }else{
        alert(`CORRECTO: ${mensaje}`)
      }
    });
  }

  convertGsmFake() {
    instance.get('/api/cronos/convert-fake-gsm')
    .then( (res) => {
      const { data } = res;
      const { success, mensaje = '', error = '' } = data;
      if(success === false){
        alert(`ERROR: ${error}`);
      }else{
        alert(`CORRECTO: ${mensaje}`)
      }
    });
  }

  convertSilence() {
    instance.get('/api/cronos/convert-all-audios')
    .then( (res) => {
      const { data } = res;
      const { success, mensaje = '', error = '' } = data;
      if(success === false){
        alert(`ERROR: ${error}`);
      }else{
        alert(`CORRECTO: ${mensaje}`)
      }
    });
  }

  render () {
    const { tag } = this.state;
    return (
      <div>
        <div>
            <h1>CRONOS</h1>
            <h4>Herramienta de corrección de parámetros de Alpha Corpus</h4>
        </div>
        <div>
          <audio
            controls
            style={{ width: '100%' }}
            src={this.state.audioURL}
            ref={this.audioRef}
          />
          <div>
            <button onClick={(e)=>this.recorrerAudiosSinDuracion(e)}>
              Establecer duración
            </button>
          </div>
          <div>
            <button onClick={(e)=>this.recorrerAudiosConInfinito(e)}>
              Remover infinitos
            </button>
          </div>
          <div>
            <button onClick={(e)=>this.establecerPromedioTodos(e)}>
              Establecer promedio usuarios
            </button>
          </div>
          <hr></hr>
          <div>
            <button onClick={(e)=>this.establecerEtiquetasPrimerasFrases(e)}>
              Establecer etiquetas primeras Frases
            </button>
          </div>
          <div>
            <button onClick={(e)=>this.establecerEtiquetasAudios(e)}>
              Establecer etiquetas Audios
            </button>
          </div>
          <br/>
          <hr></hr>
          <hr></hr>
          <div>
            <div>
              <Textarea
                label="Etiqueta de busqueda"
                onChange={this.settag}
                value={tag}
              />
            </div>
            <div>
              <button onClick={this.saveStaticontag} disabled={tag.length === 0 }>
                Agregar etiqueta "ESTATICO" a audios y frases con la etiqueta de busqueda
              </button>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

          <div>
            <button onClick={this.saveInCacheStatics}>
              Agregar a caché audios con etiqueta ESTATICO
            </button>
          </div>
          <br/>
          <hr/>
          <div>
            <button onClick={this.convertGsmFake}>
              Convertir audios a GSM (no-fake)
            </button>
          </div>

        </div>
      </div>
    )
  }
}
