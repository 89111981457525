/* 7 Mayo / 2019
 John Tapias
 Componente para consultar el histórico de los usuarios
*/
import React, { Component } from "react";
import { Row, Col, TextInput, Button, Modal, Icon } from "react-materialize";
import { ResultadoHistorico } from "./ResultadoHistorico";
import { HistoricoAPI } from "../../services/api/historico-api";

class Historico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      year: 0,
      month: 0,
      mensajeError: "",
      isError: false,
      isData: false,
      mostrar: false,
    };
    this.modificarNombre = this.modificarNombre.bind(this);
    this.modificarAnio = this.modificarAnio.bind(this);
    this.modificarMes = this.modificarMes.bind(this);
    this.validarUsuario = this.validarUsuario.bind(this);
    this.buscarUsuario = this.buscarUsuario.bind(this);
    //this.cerrarModal = this.cerrarModal.bind(this);
    //this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.iniciarDatos();
  }

  iniciarDatos() {
    const instant = new Date();
    const thisYear = instant.getFullYear();
    const thisMonth = instant.getMonth() + 1;
    this.setState({
      year: thisYear,
      month: thisMonth,
    });
  }

  modificarNombre(event) {
    const name = event.target.value;
    if (name.length <= 100) {
      this.setState({
        user: name,
      });
    }
  }

  modificarAnio(event) {
    this.setState({
      year: event.target.value,
    });
  }

  modificarMes(event) {
    if (event.target.value >= 1 && event.target.value <= 12) {
      this.setState({
        month: event.target.value,
      });
    }
  }

  validarUsuario() {
    //e.preventDefault();
    //const modalBuscarUsuario = this.modalRef.current;

    if (this.state.user === "") {
      this.setState({
        isError: true,
        mensajeError: "Debe ingresar un usuario para realizar la búsqueda.",
      });
    } else {
      this.setState(
        {
          isError: false,
        },
        () => {
          //modalBuscarUsuario.showModal();
          this.buscarUsuario();
        }
      );
    }
  }

  buscarUsuario() {
    const { user, year, month } = this.state;
    HistoricoAPI.getUserInfo(user, year, month).then((result) => {
      if (result.status === 0) {
        const { numeroAudiosCalificados } = result.info;
        const { numeroAudiosTotal } = result.info;
        const { tiempoCalificado } = result.info;
        const { tiempoTotal } = result.info;
        const { calificacionPromedio } = result.info;
        this.setState({
          isData: true,
          numAudiosCal: numeroAudiosCalificados,
          numAudiosTot: numeroAudiosTotal,
          tiempoCal: tiempoCalificado,
          tiempoTot: tiempoTotal,
          promedio: calificacionPromedio,
        });
      }
    });
  }

  renderError() {
    return (
      <div>
        <h5>
          {this.state.mensajeError}
          <Icon left>error</Icon>
        </h5>
      </div>
    );
  }

  renderBusqueda() {
    return (
      <div>
        <Row>
          <Col s={2}></Col>
          <Col s={8}>
            <Row>
              <h2 style={{ textAlign: "center", color: "#01a1dd" }}>
                Histórico
              </h2>
            </Row>
            <Row>
              <Col s={4}></Col>
              <Col
                s={4}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <TextInput
                  icon="account_circle"
                  label="Usuario"
                  onChange={this.modificarNombre}
                  value={this.state.user}
                />
              </Col>
              <Col s={4}></Col>
            </Row>
            <Row>
              <Col s={3}></Col>
              <Col s={3}>
                <TextInput
                  icon="calendar_today"
                  type="number"
                  label="Año"
                  value={this.state.year}
                  onChange={this.modificarAnio}
                />
              </Col>
              <Col s={3}>
                <TextInput
                  icon="calendar_today"
                  type="number"
                  label="Mes"
                  value={this.state.month}
                  onChange={this.modificarMes}
                />
              </Col>
              <Col s={3}></Col>
            </Row>
            <Row>
              <Col s={4}></Col>
              <Col
                s={4}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button onClick={this.validarUsuario}>
                  Buscar
                  <Icon left>search</Icon>
                </Button>
              </Col>
              <Col s={4}></Col>
            </Row>
          </Col>
          <Col s={2}></Col>
        </Row>
        <Row></Row>
      </div>
    );
  }
  renderResult() {
    return (
      <div>
        <Row>
          <h2>
            {this.state.isError ? "ERROR" : `Historial de ${this.state.user}`}
          </h2>
          <ResultadoHistorico
            isData={this.state.isData}
            numAudiosCal={this.state.numAudiosCal}
            numAudiosTot={this.state.numAudiosTot}
            tiempoCal={this.state.tiempoCal}
            tiempoTot={this.state.tiempoTot}
            promedio={this.state.promedio}
            year={this.state.year}
            month={this.state.month}
          />
        </Row>
      </div>
    );
  }

  render() {
    const componenteBusqueda = this.renderBusqueda();
    return (
      <div>{this.state.isData ? this.renderResult() : componenteBusqueda}</div>
    );
  }
}

export default Historico;
