/* eslint-disable import/prefer-default-export */
import { INSTANCE } from '..';

export class InformeAPI {
  static getInformeAsesores(day, month, year, gte) {
    return INSTANCE.get(`/api/transcripciones/reporte-dia/asesores/${year}/${month}/${day}/gte/${gte}`)
      .then(response => response.data);
  }

  static getInformeLocutores(day, month, year, gte) {
    return INSTANCE.get(`/api/transcripciones/reporte-dia/locutores/${year}/${month}/${day}/gte/${gte}`)
      .then((response) => {
        console.log(`getInformeLocutores day:${day}  month:${month}  year:${year}`);
        console.log(`response.data -> ${JSON.stringify(response.data)}`);
        return response.data;
      });
  }
}
