import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';

export class Servex extends Component {
  render() {
    return (
      <Row>
        <Col s={2} />
        <Col s={8} style={{ color: 'gray' }}>
          <h6>Servex ©</h6>
          <div>Todos los derechos Reservados </div>
        </Col>
        <Col s={2} />
      </Row>
    );
  }
}
