import React, { Component } from 'react';
import { Row, Col, Preloader, Table, Icon } from 'react-materialize';

export class ResultadoHistorico extends Component {
  constructor(props) {
    super(props);
    this.renderResultado = this.renderResultado.bind(this)
  }

  renderResultado() {
    return (
      <div>
      <Row>
        <Col s={2}><h6>Período: </h6></Col>
        <Col s={4}><h6>{this.props.month}/{this.props.year}</h6></Col>
        <Col s= {6}></Col>
      </Row>
      <Row>
        <Col s={12}>
          <Table>
            <thead>
              <tr>
                <th data-field="concepto">
                </th>
                <th data-field="calificado">
                  Calificado
                </th>
                <th data-field="total">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                Audios
                <Icon left>
                  volume_up
                </Icon>                
                </td>
                <td>
                  { this.props.numAudiosCal }
                </td>
                <td>
                  { this.props.numAudiosTot }
                </td>
              </tr>
              <tr>
                <td>
                Tiempo
                <Icon left>
                  access_time
                </Icon>                 
                </td>
                <td>
                  { this.props.tiempoCal.horas }h { this.props.tiempoCal.minutos }m { this.props.tiempoCal.segundos }s  
                </td>
                <td>
                  { this.props.tiempoTot.horas }h { this.props.tiempoTot.minutos }m { this.props.tiempoTot.segundos }s
                </td>
              </tr>                    
            </tbody>
          </Table>
        </Col>
      </Row>  
    </div>
    );
  }

  renderCargando() {
    return (
      <div>
        <Row>
          <Col s={2}>
          </Col>
          <Col s={8} style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Preloader flashing/>
          </Col>
          <Col s={2}>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const componente = this.props.isData ? this.renderResultado() : this.renderCargando();
    return (
      <div>
        {componente}
      </div>
    )
  }
}
