import { combineReducers } from 'redux';
import rankReducer from './rankReducer';
import panelReducer from './panelReducer';
import bannerReducer from './bannerReducer';

const reducersCombined = {
  rankPanel: rankReducer,
  messagePanel: panelReducer,
  bannerPanel: bannerReducer,
};

export const generalReducer = combineReducers(reducersCombined);
