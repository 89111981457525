import { connect } from 'react-redux';
import Plataforma from './index';
import {
  setInfoPanel,
  setUserId,
  showInfoPanel,
  hideInfoPanel
} from '../../redux/actions/actions';

const mapDispatchToProps = dispatch => ({
  showInfoPanel: () => dispatch(showInfoPanel()),
  hideInfoPanel: () => dispatch(hideInfoPanel()),
  setUserId: userID => dispatch(setUserId(userID)),
  setInfoPanel: speaker => dispatch(setInfoPanel(speaker))
});

const PlataformaContainer = connect(
  null,
  mapDispatchToProps
)(Plataforma);

export default PlataformaContainer;
