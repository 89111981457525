/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import './style.css';
import BannerContainer from './banner/BannerContainer';
import { Footer } from './footer';

import Home from '../home';
import Evaluar from '../evaluar';
import AudioContainer from '../audio/AudioContainer';
// import AudioContainerBR from '../audio-br/AudioContainer';
import LocutorContainer from '../locutor/LocutorContainer';
// import LocutorContainerBR from '../locutor-br/LocutorContainer';
import Ranking from '../ranking';
import { Cronos } from '../cronos';
import Historico from '../historico';
import Informe from '../informe';
import SearchAudios from '../searchAudios'
import DeleteFrases from '../borrar-frase'
import PlataformaContainer from '../plataforma/PlataformaContainer';

import Navbar from '../navigation/Navbar';
// import PlataformaContainerBR from '../plataforma-br/PlataformaContainer';

const rutaCronos = process.env.REACT_APP_RUTA_CRONOS || '/cronos';
const rutaAudio = process.env.REACT_APP_RUTA_AUDIO || '/audio';
// const rutaAudioBR = process.env.REACT_APP_RUTA_AUDIO_BR || '/audio-br';
const rutaEvaluar = process.env.REACT_APP_RUTA_EVALUAR || '/evaluar';
const rutaLocutor = process.env.REACT_APP_RUTA_LOCUTOR || '/44B1229C573B9E7D93D952599F7FAD40';
// const rutaLocutorBR = process.env.REACT_APP_RUTA_LOCUTOR_BR || '/locutor-br';
const rutaRanking = process.env.REACT_APP_RUTA_RANKING || '/ranking';
const rutaHistorico = process.env.REACT_APP_RUTA_HISTORICO || '/historico';
const rutaPlataforma = process.env.REACT_APP_RUTA_PLATAFORMA || '/plataforma';
// const rutaPlataformaBR = process.env.REACT_APP_RUTA_PLATAFORMA_BR || '/plataforma-br';
const rutaInforme = process.env.REACT_APP_RUTA_INFORME || '/informe';
const rutaSearchAudios = '/new';
const rutaDeleteAudio = '/DeleteFrase';

export class Principal extends Component {
  render() {
    return (
      <div>
        <Navbar/>  
      <div className="caja">
        <BannerContainer />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path={rutaAudio} component={AudioContainer} />
          {/* <Route path={rutaAudioBR} component={AudioContainerBR} /> */}
          <Route path={rutaEvaluar} component={Evaluar} />
          <Route path={rutaLocutor} component={LocutorContainer} />
          {/* <Route path={rutaLocutorBR} component={LocutorContainerBR} /> */}
          <Route path={rutaRanking} component={Ranking} />
          <Route path={rutaHistorico} component={Historico} />
          <Route path={rutaInforme} component={Informe} />
          <Route path={rutaPlataforma} component={PlataformaContainer} />
          {/* <Route path={rutaPlataformaBR} component={PlataformaContainerBR} /> */}
          <Route path={rutaCronos} component={Cronos} />
          <Route path={rutaSearchAudios} component={SearchAudios} />
          <Route path={rutaDeleteAudio} component={DeleteFrases} />
        </Switch>
        <Footer />
      </div>
      </div>
    );
  }
}
