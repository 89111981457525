/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Row,
  Icon
} from 'react-materialize';
import Dropzone from 'react-dropzone';
import Archivo from './archivo';

export default class Uploader extends Component {

  constructor(props) {
    super(props);
    this.renderListaAudios = this.renderListaAudios.bind(this);
  }

  renderListaAudios() {
    const {
      archivos,
      estados,
      eliminarArchivo,
      enviarArchivo
    } = this.props;
    return (
      <div>
        {
          archivos.map(archivo => (
            <Archivo
              key={archivo.path}
              archivo={archivo}
              estado={estados[archivo.path]}
              eliminarArchivo={eliminarArchivo}
              enviarArchivo={enviarArchivo}
            />
          ))
        }
      </div>
    );
  }

  renderBotonSubir() {
    // const { getRootProps, getInputProps, isDragActive } = useDropzone(this.arrojarArchivos);
    const { agregarArchivos } = this.props;
    return (
      <Dropzone onDrop={acceptedFiles => agregarArchivos(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section
            className="container"
            style={{
              borderStyle: 'dotted',
              borderColor: '#c0bebe',
              color: '#26a69a',
              backgroundColor: 'rgb(243, 243, 238)',
              textAlign: 'center'
            }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <h5 style={{ color: '#d5d4d4', margin: '5vh' }}>
                <Icon left>
                  file_copy
                </Icon>
                Arrojar audios aquí o click para seleccionarlos
              </h5>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }

  render() {
    const { isArchivo } = this.props;
    const componenteBotonSubir = this.renderBotonSubir();
    return (
      <section>
        <Row>
          {componenteBotonSubir}
        </Row>
        <Row>
          { isArchivo ? this.renderListaAudios() : null}
        </Row>
      </section>
    );
  }
}
