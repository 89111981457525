import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import { Servex } from './servex';
import PanelContainer from './panel/PanelContainer';

export class Footer extends Component {

  render () {
    return (
      <div style={{paddingTop:'5vh'}}>
        {!this.props.showFooter ? (
          <Row>
            <Col s={4}>
              <PanelContainer/>
            </Col>
            <Col s={2}>
            </Col>
            <Col s={2}>
              <img
              src='/assets/logo_servex.png'
              alt='logo'
              style={{ height: '32px' }}
              />
            </Col>
            <Col s={4}>
              <Servex></Servex>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}