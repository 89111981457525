/* eslint-disable import/prefer-default-export */
import { INSTANCE } from '..';

export class PlataformaAPI {
  static getLabels() {
    return INSTANCE.get('/api/frases/etiquetas')
      .then(response => response.data);
  }

  static getLabelsLocutor(etiquetaOriginal) {
    return INSTANCE.get(`/api/frases/etiquetas/${etiquetaOriginal}`)
      .then(response => response.data);
  }

  static getFilteredLabels(filters) {
    return INSTANCE.post('/api/frases/filtros', { filtros: filters })
      .then(response => response.data);
  }

  static getBatches() {
    return INSTANCE.get('/api/lotes/')
      .then(response => response.data);
  }

  static getPhrasesByBatch(batch) {
    // eslint-disable-next-line no-underscore-dangle
    return INSTANCE.get(`/api/frases/lote/${batch._id}`)
      .then(response => response.data);
  }

  static saveNewSentence(autor, lote, sentence) {
    return INSTANCE.post('/api/frases/',
      {
        lote: lote.toLowerCase(),
        autor,
        frases: [sentence]
      })
      .then(response => response.data);
  }
}
