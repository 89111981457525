/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import {
  Button,
  Icon,
  Row,
  Col,
  Preloader
} from 'react-materialize';
import './style.css';

const mimeType = process.env.REACT_APP_MIME_WAV_AUDIOS;
const REDIRECT_SECONDS = process.env.REACT_APP_AUDIO_REDIRECT_SECONDS || 0;

export class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frase: this.props.idFrase,
      isMicro: false,
      isRecording: false,
      isFinish: false,
      isAudio: false,
      audioURL: null,
      audioDuration: 0,
      segundos: REDIRECT_SECONDS
    };
    this.audioRef = React.createRef();
    this.resetRecorder = this.resetRecorder.bind(this);
    this.cancelarGrabacion = this.cancelarGrabacion.bind(this);
    this.terminarGrabacion = this.terminarGrabacion.bind(this);    
  }

  componentDidMount() {
    this.props.setStatusMessage({      
      message: 'Micrófono sin permiso',
      code: 1
    });
    // Captura de audio
    navigator.getUserMedia = navigator.getUserMedia
      || navigator.webkitGetUserMedia
      || navigator.mozGetUserMedia
      || navigator.msGetUserMedia
      || navigator.oGetUserMedia;

    const constraints = { audio: true };
    this.chunks = [];
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // Constructor de MediaRecorder
          this.mediaRecorder = new MediaRecorder(stream);
          const m = this.mediaRecorder;          

          // Almacena la data en chunks
          this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data);

          this.mediaRecorder.onstop = () => {
            this.state.isAudio ? this.mostrarAudio() : this.eliminarAudio();
          };

          // Cambia el estado
          this.setState({
            isMicro: true
          }, () => {
            this.props.setStatusMessage({
              message: 'Preparado para grabar',
              code: 0
            });
          });
        })
        .catch((err) => {
          // No hay dispositivo para satisfacer la petición
          if (err.name === 'NotFoundError') {
            // NotFoundError
            // Mostrar botón para continuar
            this.props.onError('No cuenta con micrófono',);
          }
        });
    } else {
      console.error('getUserMedia unsupported.');
    }
  }

  comenzarGrabacion(e) {
    e.preventDefault();
    this.chunks = [];
    this.props.changeShowList(false);
    this.setState(
      {
        isRecording: true,
        isAudio: false,
        audioURL: null
      },
      () => {
        // The media will be captured in
        // Separate chunks of that duration
        this.mediaRecorder.start();
        this.props.setStatusMessage({
          message: 'Grabando',
          code: 0
        });
      }
    );
  }

  cancelarGrabacion(e) {
    this.props.changeShowList(true);
    e.preventDefault();
    this.mediaRecorder.stop();
  }

  eliminarGrabacion(e) {
    e.preventDefault();
    this.props.changeShowList(true);
    this.eliminarAudio();
  }

  eliminarAudio() {
    this.chunks = [];
    this.setState({
      isMicro: true,
      isRecording: false,
      isAudio: false,
      audioURL: null
    }, () => {
      this.props.setStatusMessage({
        message: 'Preparado para grabar',
        code: 1
      });
    });
  }

  terminarGrabacion(e) {
    e.preventDefault();
    this.setState({
      isRecording: false,
      isAudio: true
    }, () => {
      this.props.setStatusMessage({
        message: 'Verificando',
        code: 1
      });
      this.mediaRecorder.stop();
    });
  }

  mostrarAudio() {
    const audioBlob = new Blob(this.chunks, { type: mimeType });
    const audioURL = URL.createObjectURL(audioBlob);
    // Chrome's bug fix ---------------------------------
    this.audioRef.current.ondurationchange = () => {
      if (this.audioRef.current.duration !== Infinity) {
        this.setState({
          audioDuration: this.audioRef.current.duration
        });
      }
    };
    this.audioRef.current.onloadedmetadata = () => {
      if (this.audioRef.current.duration !== Infinity) {
        this.setState({
          audioDuration: this.audioRef.current.duration
        });
      }
    };
    // Chrome's bug fix ---------------------------------
    this.setState({
      audioURL
    });
  }

  guardarAudio() {
    const { audioDuration } = this.state;

/*     if (audioDuration <= 0) {
      alert('Audio sin duración :(');
      this.props.setStatusMessage({
        message: 'VOLVER A GRABAR',
        code: 0
      });
    } else { */
    // Audio con duración
    // Convertir chunks en un blob
    const blob = new Blob(this.chunks, { type: mimeType });      
    this.setState({
      isFinish: true
    }, () => {
      // Enviando blob
      this.props.setStatusMessage({
        message: 'Enviando...',
        code: 0
      });
      this.props.onAudio(blob, audioDuration, this.resetRecorder);
    });
    //}
  }

  resetRecorder() {
    const { setStatusMessage } = this.props;
    this.props.changeShowList(true);
    this.chunks = [];
    this.setState({
      isFinish: false,
      isMicro: true,
      isRecording: false,
      isAudio: false,
      audioURL: null
    }, () => {
      setStatusMessage({
        message: 'Preparado para grabar',
        code: 1
      });
    });
  }

  renderIniciarGrabacion() {
    return (
      <Row>
        <Col s={4} />
        <Col s={4}>
          <Button
            className="green accent-4 button-center"
            waves="light"
            onClick={e => this.comenzarGrabacion(e)}
          >
            Iniciar Grabación
            <Icon>mic</Icon>
          </Button>
        </Col>
        <Col s={4} />
      </Row>
    );
  }

  renderGrabando() {
    return (
      <Row>
        <Col s={6}>
          <Button
            className='deep-orange accent-2 button-center'
            waves='light'
            onClick={e => this.cancelarGrabacion(e)}
          >
            Cancelar Grabación
            <Icon>cached</Icon>
          </Button>
        </Col>
        <Col s={6}>
          <Button
            className='light-green accent-4 button-center'
            onClick={e => this.terminarGrabacion(e)}
          >
            Terminar Grabación
            <Icon>check_circle</Icon>
          </Button>
        </Col>
      </Row>
    );
  }

  renderVerificarGrabacion() {
    return (
      <div>
        <Row>
          <Col s={12}>
            <audio
              controls
              style={{ width: '100%' }}
              src={this.state.audioURL}
              ref={this.audioRef}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <Button
              className="deep-orange accent-4 button-center"
              waves="light"
              onClick={e => this.eliminarGrabacion(e)}
            >
              Eliminar Grabación
              <Icon>cancel</Icon>
            </Button>
          </Col>
          <Col s={6}>
            <Button
              className="green accent-4 button-center"
              waves="light"
              onClick={e => this.guardarAudio(e)}
            >
              Enviar Grabación
              <Icon>forward</Icon>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderEnviandoGrabacion() {
    return (
      <Row>
        <Col s={12}>
          <Row>
            <Col s={4} />
            <Col s={4}>
              <Preloader flashing/>
            </Col>
            <Col s={4} />
          </Row>
        </Col>
      </Row>
    );
  }

  renderComponente() {
    const {
      isFinish,
      isMicro,
      isRecording,
      isAudio
    } = this.state;
    let componente = null;
    if (isFinish) {
      componente = this.renderEnviandoGrabacion();
    } else if (isMicro) {
      if (isRecording) {
        componente = this.renderGrabando();
      } else if (!isRecording && !isAudio) {
        componente = this.renderIniciarGrabacion();
      } else if (!isRecording && isAudio) {
        componente = this.renderVerificarGrabacion();
      }
    }
    return componente;
  }

  render() {
    const componente = this.renderComponente();
    return (
      <div className="div-center">
        {componente}
      </div>
    );
  }
}
