/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
/* 14 Junio / 2019
 John Tapias
 Componente para consultar el histórico de los usuarios
*/
import React, { Component } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Preloader
} from 'react-materialize';
import { InformeAPI } from '../../services/api/informe-api';

const gte = process.env.REACT_APP_DEFAULT_GTE || 7;
const META_AUDIOS_DIA_ASESOR = process.env.REACT_APP_INFORME_META_AUDIOS_ASESOR || 2000;
const META_TIEMPO_DIA_LOCUTOR = process.env.REACT_APP_INFORME_META_TIEMPO_LOCUTOR || 3;

class Informe extends Component {
  static convertirHoras(tiempo) {
    let { horas, minutos } = tiempo;
    horas = parseInt(horas, 10);
    minutos = parseInt(minutos, 10);
    horas += parseFloat((minutos / 60).toFixed(1));
    return horas;
  }

  constructor(props) {
    super(props);
    this.state = {
      semanaAsesores: {},
      semanaLocutores: {},
      mostrarInformeAsesores: false,
      mostrarInformeLocutores: false,
      fechaMostrar: [],
      diasSemana: [],
      locutores: []
    };
    this.buscarDatosAsesores = this.buscarDatosAsesores.bind(this);
    this.buscarDatosLocutores = this.buscarDatosLocutores.bind(this);
    this.calcularFechas = this.calcularFechas.bind(this);
  }

  componentDidMount() {
    this.calcularFechas('hoy', null);
  }

  calcularFechas(cuando, cb) {
    const { fecha } = this.state;
    const dias = [];
    const fechaMostrar = [];
    // Calcular dias de la semana
    let nuevaFecha = null;
    if (cuando === 'hoy') {
      nuevaFecha = new Date();
      nuevaFecha = new Date(
        nuevaFecha.getFullYear(),
        nuevaFecha.getMonth(),
        nuevaFecha.getDate()
      );
    } else if (cuando === 'semana-anterior') {
      nuevaFecha = new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate() - 7
      );
    } else if (cuando === 'semana-siguiente') {
      nuevaFecha = new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate() + 7
      );
    }
    // Dias de la semana de 0 a 6
    // Lunes es 1
    const hoy = nuevaFecha.getDay();
    // Dias de la semana antes de hoy
    for (let x = 1; x <= hoy; x += 1) {
      const distancia = hoy - x;
      dias.push(nuevaFecha.getDate() - distancia);
      fechaMostrar.push(new Date(
        nuevaFecha.getFullYear(),
        nuevaFecha.getMonth(),
        nuevaFecha.getDate() - distancia
      ));
    }
    // Dias de la semana después de hoy
    for (let x = hoy + 1; x <= 6; x += 1) {
      const distancia = x - hoy;
      dias.push(nuevaFecha.getDate() + distancia);
      fechaMostrar.push(new Date(
        nuevaFecha.getFullYear(),
        nuevaFecha.getMonth(),
        nuevaFecha.getDate() + distancia
      ));
    }
    this.setState({
      diasSemana: dias,
      mes: nuevaFecha.getMonth() + 1,
      anio: nuevaFecha.getFullYear(),
      fechaMostrar,
      fecha: nuevaFecha
    }, () => {
      if (cb !== null) {
        cb();
      }
    });
  }

  buscarDatosAsesores() {
    // Obtener datos
    try {
      // Procesamiento Serial
      const procesarDiaPorDiaAsesor = async (estado) => {
        const {
          semanaAsesores,
          diasSemana,
          mes,
          anio
        } = estado;
        for (const dia of diasSemana) {
          await InformeAPI.getInformeAsesores(dia, mes, anio, gte).then((response) => {
            semanaAsesores[dia] = response;
            this.setState({
              semanaAsesores
            });
            return 1;
          });
        }
      };
      procesarDiaPorDiaAsesor(this.state);
    } catch (error) {
      console.log(error);
      throw (error);
    }
  }

  buscarDatosLocutores() {
    // Obtener datos
    try {
      // Procesamiento serial
      const procesarDiaPorDia = async (estado) => {
        const {
          semanaLocutores,
          diasSemana,
          mes,
          anio,
          locutores
        } = estado;
        for (const diaSemana of diasSemana) {
          await InformeAPI.getInformeLocutores(diaSemana, mes, anio, gte).then((response) => {
            const { status, dia, informe } = response;
            if (response && status === 0) {
              semanaLocutores[dia] = informe;
              if (Object.keys(informe).length > 0) {
                Object.keys(response.informe).map((locutor) => {
                  if (!Object.keys(response.informe)) {
                    locutores.push(locutor);
                  }
                  return 1;
                });
              }
              this.setState({
                semanaLocutores,
                locutores
              });
            }
          });
        }
        return 1;
      };
      procesarDiaPorDia(this.state);
    } catch (error) {
      console.log(error);
      throw (error);
    }
  }

  renderTitulo() {
    const { mostrarInformeAsesores, mostrarInformeLocutores } = this.state;
    const asesores = mostrarInformeAsesores ? 'Asesores' : null;
    const locutores = mostrarInformeLocutores ? 'Locutores' : null;
    return (
      <Row>
        <h2 style={{ textAlign: 'center', color: '#01a1dd' }}>
          Informe {asesores}{locutores}
        </h2>
      </Row>
    );
  }

  renderInformeAsesores() {
    let { diasSemana } = this.state;
    const { semanaAsesores, fechaMostrar } = this.state;
    diasSemana = diasSemana.map(d => d.toString());

    return (
      <div>
        <Row>
          <Col s={3} />
          <Col s={3}>
            <Button onClick={() => {
              this.calcularFechas('semana-anterior', this.buscarDatosAsesores);
            }}
            >
              Anterior
            </Button>
          </Col>
          <Col s={3}>
            <Button onClick={() => {
              this.calcularFechas('semana-siguiente', this.buscarDatosAsesores);
            }}
            >
              Siguiente
            </Button>
          </Col>
          <Col s={3} />
        </Row>
        <Table>
          <thead>
            <tr>
              <th>
                INDICADOR/DÍA
              </th>
              <th>
                Lunes
              </th>
              <th>
                Martes
              </th>
              <th>
                Miércoles
              </th>
              <th>
                Jueves
              </th>
              <th>
                Viernes
              </th>
              <th>
                Sábado
              </th>
            </tr>
            <tr>
              <th />
              {
                fechaMostrar.map((d) => {
                  return (<th key={d.getDay()}>{d.getDate()}/{d.getMonth() + 1}</th>);
                })
              }

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Meta</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
              <td>{META_AUDIOS_DIA_ASESOR}</td>
            </tr>
            <tr>
              <td>Audios Totales</td>
              {diasSemana.map((item, key) => {
                return (
                  <td key={key}>
                    {
                      Object.keys(semanaAsesores).includes(diasSemana[key])
                        ? semanaAsesores[diasSemana[key]].numeroAudiosTotal
                        : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Audios con duración</td>
              {diasSemana.map((item, key) => {
                return (
                  <td key={key}>
                    {
                      Object.keys(semanaAsesores).includes(diasSemana[key])
                        ? semanaAsesores[diasSemana[key]].numeroAudiosConDuracion
                        : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Avance Meta Bruta</td>
              {diasSemana.map((item, key) => {
                return (
                  <td key={key}>
                    {
                      Object.keys(semanaAsesores).includes(diasSemana[key])
                        ? `${((semanaAsesores[diasSemana[key]].numeroAudiosTotal / META_AUDIOS_DIA_ASESOR) * 100).toFixed(2)}%`
                        : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td />
            </tr>
            <tr>
              <td>Audios con evaluación</td>
              {diasSemana.map((item, key) => {
                return (
                  <td key={key}>
                    {
                      Object.keys(semanaAsesores).includes(diasSemana[key])
                        ? semanaAsesores[diasSemana[key]].numeroAudiosConEvaluacion
                        : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Productividad QA</td>
              {diasSemana.map((item, key) => {
                if (Object.keys(semanaAsesores).includes(diasSemana[key])) {
                  if (semanaAsesores[diasSemana[key]].numeroAudiosTotal === 0) {
                    return (<td key={key}>0.00%</td>);
                  } else {
                    return (<td key={key}>{`${((semanaAsesores[diasSemana[key]].numeroAudiosConEvaluacion / semanaAsesores[diasSemana[key]].numeroAudiosConDuracion) * 100).toFixed(2)}%`}</td>);
                  }
                } else {
                  return (<td key={key}><Preloader flashing /></td>);
                }
              })
              }
            </tr>
            <tr>
              <td />
            </tr>
            <tr>
              <td>Audios Efectivos</td>
              {diasSemana.map((item, key) => {
                return (
                  <td key={key}>
                    {
                      Object.keys(semanaAsesores).includes(diasSemana[key])
                        ? semanaAsesores[diasSemana[key]].numeroAudiosGTE
                        : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Calidad Asesor Neta</td>
              {diasSemana.map((item, key) => {
                if (Object.keys(semanaAsesores).includes(diasSemana[key])) {
                  if (semanaAsesores[diasSemana[key]].numeroAudiosConEvaluacion === 0) {
                    return (<td key={key}>0.00%</td>);
                  } else {
                    return (<td key={key}>{`${((semanaAsesores[diasSemana[key]].numeroAudiosGTE / semanaAsesores[diasSemana[key]].numeroAudiosConEvaluacion) * 100).toFixed(2)}%`}</td>);
                  }
                } else {
                  return (<td key={key}><Preloader flashing /></td>);
                }
              })
              }
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Avance Real</td>
              {diasSemana.map((item, key) => {
                if (Object.keys(semanaAsesores).includes(diasSemana[key])) {
                  if (META_AUDIOS_DIA_ASESOR === 0) {
                    return (<td key={key}>0.00%</td>);
                  } else {
                    return (<td key={key}>{`${((semanaAsesores[diasSemana[key]].numeroAudiosGTE / META_AUDIOS_DIA_ASESOR) * 100).toFixed(2)}%`}</td>);
                  }
                } else {
                  return (<td key={key}><Preloader flashing /></td>);
                }
              })
              }
            </tr>
          </tbody>
        </Table>
        <Row>
          <Button onClick={() => {
            this.setState({
              mostrarInformeAsesores: false,
              mostrarInformeLocutores: false
            });
          }}
          >
            Regresar
          </Button>
        </Row>
      </div>
    );
  }

  renderInformeLocutores() {
    const {
      semanaLocutores,
      fechaMostrar,
      diasSemana
    } = this.state;

    return (
      <div>
        {Object.keys(semanaLocutores).length !== 0
          ? (
            <Row>
              <Col s={3} />
              <Col s={3}>
                <Button onClick={() => {
                  this.setState({
                    semanaLocutores: {}
                  }, this.calcularFechas('semana-anterior', this.buscarDatosLocutores));
                }}
                >
                  Anterior
                </Button>
              </Col>
              <Col s={3}>
                <Button onClick={() => {
                  this.setState({
                    semanaLocutores: {}
                  }, this.calcularFechas('semana-siguiente', this.buscarDatosLocutores));
                }}
                >
                  Siguiente
                </Button>
              </Col>
              <Col s={3} />
            </Row>
          )
          : null
          }

        <Table>
          <thead>
            <tr>
              <th>
                INDICADOR/DÍA
              </th>
              <th>
                Lunes
              </th>
              <th>
                Martes
              </th>
              <th>
                Miércoles
              </th>
              <th>
                Jueves
              </th>
              <th>
                Viernes
              </th>
              <th>
                Sábado
              </th>
            </tr>
            <tr>
              <th />
              {
                fechaMostrar.map((d) => {
                  return (<th key={d.getDay()}>{d.getDate()}/{d.getMonth() + 1}</th>);
                })
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Audios Totales</td>
              {diasSemana.map((diaN, keyDia) => {
                // Valida si ya recupero el dia
                const dia = diaN.toString();
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {semanaLocutores[dia][locutor].numeroAudiosTotal}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Audios con evaluación</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {semanaLocutores[dia][locutor].numeroAudiosConEvaluacion}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Productividad QA</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {((semanaLocutores[dia][locutor].numeroAudiosConEvaluacion / semanaLocutores[dia][locutor].numeroAudiosTotal) * 100).toFixed(2)}%
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td />
            </tr>
            <tr>
              <td>Meta (horas)</td>
              <td>{META_TIEMPO_DIA_LOCUTOR}</td>
              <td>{META_TIEMPO_DIA_LOCUTOR}</td>
              <td>{META_TIEMPO_DIA_LOCUTOR}</td>
              <td>{META_TIEMPO_DIA_LOCUTOR}</td>
              <td>{META_TIEMPO_DIA_LOCUTOR}</td>
              <td>{0}</td>
            </tr>
            <tr>
              <td>Tiempo Total</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {Informe.convertirHoras(semanaLocutores[dia][locutor].tiempoAudiosTotal)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Avance Meta Bruta</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {((Informe.convertirHoras(semanaLocutores[dia][locutor].tiempoAudiosTotal) / META_TIEMPO_DIA_LOCUTOR) * 100).toFixed(2)}%
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Tiempo Evaluado</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {Informe.convertirHoras(semanaLocutores[dia][locutor].tiempoAudiosEvaluados)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Tiempo Efectivo</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {Informe.convertirHoras(semanaLocutores[dia][locutor].tiempoAudiosGTE)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>

            <tr>
              <td />
            </tr>
            <tr style={{ textAlign: 'center', color: '#01a1dd' }}>
              <td>Avance Real</td>
              {diasSemana.map((diaN, keyDia) => {
                const dia = diaN.toString();
                // Valida si ya recupero el dia
                return (
                  <td key={dia}>
                    {Object.keys(semanaLocutores).includes(dia)
                      ? Object.keys(semanaLocutores[dia])
                        .sort()
                        .map((locutor, keyLocutor) => {
                        // Extrae el key de cada locutor
                          return (
                            <div key={locutor}>
                              <Row>
                                <Col s={4}>
                                  {locutor.split('locutor-')[1]}
                                </Col>
                                <Col s={4} />
                                <Col s={4}>
                                  {((Informe.convertirHoras(semanaLocutores[dia][locutor].tiempoAudiosGTE) / META_TIEMPO_DIA_LOCUTOR) * 100).toFixed(2)}%
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      : (<Preloader flashing />)
                    }
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>

        <Row>
          <Button onClick={() => {
            this.setState({
              mostrarInformeAsesores: false,
              mostrarInformeLocutores: false
            });
          }}
          >
            Regresar
          </Button>
        </Row>
      </div>
    );
  }

  renderTabs() {
    return (
      <Row>
        <Col s={3} />
        <Col s={3}>
          <Button onClick={() => {
            this.setState({
              mostrarInformeAsesores: true,
              mostrarInformeLocutores: false
            }, this.buscarDatosAsesores());
          }}
          >Asesores
          </Button>
        </Col>
        <Col s={3}>
          <Button
            onClick={() => {
              this.setState({
                mostrarInformeAsesores: false,
                mostrarInformeLocutores: true
              }, this.buscarDatosLocutores());
            }}
          >Locutores
          </Button>
        </Col>
        <Col s={3} />
      </Row>
    );
  }

  render() {
    const { mostrarInformeAsesores, mostrarInformeLocutores } = this.state;
    const componenteTitulo = this.renderTitulo();
    const componenteTabs = this.renderTabs();
    return (
      <div>
        <div>
          {
          componenteTitulo
          }
        </div>
        {!mostrarInformeAsesores && !mostrarInformeLocutores
          ? (<div>{ componenteTabs }</div>)
          : null
        }
        {mostrarInformeAsesores
          ? this.renderInformeAsesores() : null
        }
        {mostrarInformeLocutores
          ? this.renderInformeLocutores() : null
        }
      </div>
    );
  }
}

export default Informe;
