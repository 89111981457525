/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import {
  Button,
  Icon,
  Row,
  Col,
  Textarea,
  Card,
  Preloader,
  Badge
} from 'react-materialize';

import moment from 'moment';
import { Estrellas } from './estrella';

import Filters from './filters';


import { INSTANCE } from '../../services/api';
import { EvaluarAPI } from '../../services/api/evaluar-api';
import { PlataformaAPI } from '../../services/api/plataforma-api';
import { LotesAPI } from '../../services/api/lotes-api';


const NUM_RAND_IM = process.env.REACT_APP_NUMERO_IMAGENES_AGRADECIMIENTO || 14;

class Evaluar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      idAudio: '',
      frase: '',
      observacion: '',
      audioURL: '',
      imagenURL: '',
      nombreMonitor: '',
      isStart: false,
      isFilter: false,
      finalizar: false,
      error: false,
      isEmptyError: false,
      labels: [],
      lotes: [],
      filtered: false,
      pending: 0,
      filter: {
        from: undefined,
        to: undefined,
        batch: [],
        tags: [],
        typeDate: '',
        typeDateText: ''
      }
    };
    this.cargarInsumos = this.cargarInsumos.bind(this);
    this.enviarEvaluacion = this.enviarEvaluacion.bind(this);
    this.cambiarObservacion = this.cambiarObservacion.bind(this);
    this.renderEvaluar = this.renderEvaluar.bind(this);
    this.guardarNombre = this.guardarNombre.bind(this);
    this.getLotesAndLabels = this.getLotesAndLabels.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.filterBar = this.filterBar.bind(this);
  }

  static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  componentWillMount() {
    this.getLotesAndLabels();
  }

  onChange(event) {
    this.setState({
      nombreMonitor: event.target.value,
      isEmptyError: false
    });
  }


  getLotesAndLabels() {
    // Get Lotes from backend
    LotesAPI.getLotes().then((response1) => {
      const {
        status,
        lotes = [],
        error = 'No se pudo cargar los Lotes.'
      } = response1;
      if (status === 0) {
        this.setState({ lotes });
      } else {
        this.setState({
          error: true,
          mensajeError: `${error} Por favor, recargue la página.`
        });
      }
    });
    PlataformaAPI.getLabels().then((response1) => {
      const {
        status,
        etiquetas = [],
        error = 'No se pudo cargar las etiquetas.'
      } = response1;
      if (status === 0) {
        this.setState({ labels: etiquetas });
      } else {
        this.setState({
          error: true,
          mensajeError: `${error} Por favor, recargue la página.`
        });
      }
    });
  }

  cambiarObservacion(event) {
    this.setState({
      observacion: event.target.value
    });
  }


  enviarEvaluacion(evaluacion, recargarEstrellas) {
    const { idAudio, observacion, nombreMonitor } = this.state;
    const nombreMonitorLimpio = nombreMonitor.trim().toLowerCase().replace(' ', '-');
    INSTANCE.post('/api/evaluaciones/', {
      idAudio,
      valoracion: evaluacion,
      observacion,
      autor: nombreMonitorLimpio
    }).then((response) => {
      const { data } = response;
      if (data.status === 0) { // ok
        this.setState(
          {
            finalizar: true
          },
          () => {
            // Limpiar evaluacion anterior
            recargarEstrellas();
          }
        );
      } else {
        this.setState(
          {
            error: true,
            mensajeError: response.data.mensaje
          },
          () => {
            // Limpiar evaluacion anterior
            recargarEstrellas();
          }
        );
      }
    });
  }

  cargarInsumos() {
    // Traer la frase y la metadata
    // del audio desde el servidor
    const { filter, filtered } = this.state;
    this.setState({loading: true});

    EvaluarAPI.getRandPhrase(filter)
      .then((response1) => {
        const {
          error,
          serverURL,
          nombreAudio,
          idAudio,
          texto
        } = response1.data;
        // No existe error
        if (error === 0) {
          const urlImagen = `/assets/thanks/${Evaluar.getRandomInt(1, NUM_RAND_IM)}.png`;
          const audioURL = `${serverURL}/api/audios/archivo/nombre/${nombreAudio}`;
          this.setState({
            idAudio,
            frase: texto,
            audioURL,
            finalizar: false,
            observacion: '',
            imagenURL: urlImagen,
            loading: false,
          });
        } else if (error === 1) {
          // Todos los audios han sido calificados
          this.setState({
            error: true,
            mensajeError: filtered
              ? 'No existen resultados con el filtro aplicado'
              : 'Felicitaciones: has terminado por hoy.'
          });
        } else if (error === 2) {
          // Error al buscar datos
          this.setState({
            error: true,
            mensajeError: 'Error: refresca la página nuevamente.'
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: true,
          mensajeError: err.toString()
        });
      });

    EvaluarAPI.countRandPhrase(filter).then((response1) => {
      this.setState({ pending: response1.count });
    });
  }

  guardarNombre() {
    const { nombreMonitor } = this.state;
    if (nombreMonitor !== '') {
      this.setState({
        isStart: true
      });
    } else {
      this.setState({
        isEmptyError: true
      });
    }
  }

  changeFilter(filter) {
    const filtered = !(
      filter.from === undefined
      && filter.to === undefined
      && filter.tags.length === 0
      && filter.batch.length === 0
    );
    this.setState({ filter, filtered, isFilter: true }, this.cargarInsumos);
  }

  filterBar(show) {
    const { pending } = this.state;
    return (
      <Row className="valign-wrapper grey lighten-3">
        <Col
          className="valign-wrapper"
          m={1}
          s={2}
          style={{
            cursor: 'pointer',
            fontWeight: 100
          }}
          onClick={() => { this.setState({ isFilter: false, error: false }); }}
        >
          <Icon center className="teal white-text">
            keyboard_arrow_left
          </Icon>
        </Col>
        <Col s={9} m={10}>
          {show}
        </Col>
        <Col>
          <Badge className="orange">
            {pending}
          </Badge>
        </Col>
      </Row>
    );
  }

  renderError() {
    const { mensajeError, filtered } = this.state;
    return (
      <Row>
        <Col s={12}>
          { filtered ? this.filterBar('Volver a filtros') : ''}
          <h3 style={{ color: 'red' }}>{mensajeError}</h3>
        </Col>
      </Row>
    );
  }

  renderEvaluar() {
    const {
      audioURL,
      frase,
      observacion,
      nombreMonitor,
      filter,
      lotes,
      loading,
    } = this.state;

    const {
      typeDate, from, to, tags, batch, typeDateText
    } = filter;

    let filterShow = 'Cargando';

    if (typeDate === '' && tags.length === 0 && batch.length === 0) {
      filterShow = 'Sin filtros';
    } else {
      filterShow = [];
      if (typeDate !== '' && (from !== undefined || to !== undefined)) {
        filterShow.push(
          <div key="date">
            <span style={{ fontWeight: 'bold' }}>{typeDateText}</span>
:
            {' '}
            {from === undefined ? '' : moment(from).format('DD/MM/YYYY')}
            {from !== undefined && to !== undefined ? ' - ' : ''}
            {to === undefined ? '' : moment(to).format('DD/MM/YYYY')}
          </div>
        );
      }
      if (batch.length > 0) {
        filterShow.push(
          <div key="batch">
            <span style={{ fontWeight: 'bold' }}>Lotes</span>
:
            {' '}
            {batch.slice().map((item) => {
              const id = '_id';
              const findtag = lotes.find(lote => lote[id] === item);
              return findtag === undefined ? item : findtag.nombre;
            }).join(', ')}
          </div>
        );
      }
      if (tags.length > 0) {
        filterShow.push(
          <div key="tags">
            <span style={{ fontWeight: 'bold' }}>Etiquetas</span>
:
            {' '}
            {tags.slice().join(', ')}
          </div>
        );
      }
    }


    return (
      <div>
        <h3 style={{ textAlign: 'center', color: '#01a1dd' }}>Evaluar</h3>
        <div>
          <span style={{ color: '#01a1dd' }}>Monitor virtual: </span>
          <span>{`${nombreMonitor}`}</span>
        </div>
        {this.filterBar(filterShow)}
        <blockquote>
          <h5>{frase}</h5>
        </blockquote>
        <audio controls style={{ width: '100%' }} src={audioURL} />
        <Row>
          <Col s={4} />
          <Col s={4} style={{ textAlign: 'center' }}>
            <Button
              floating
              large
              className="green accent-4 button-center"
              onClick={this.cargarInsumos}
              style={{ margin: '0 auto' }}
              disabled={loading}
            >
              <Icon>cached</Icon>
            </Button>
          </Col>
          <Col s={4} />
        </Row>
        <Row>
          <Textarea
            label="Observaciones"
            onChange={this.cambiarObservacion}
            value={observacion}
            disabled={loading}
          />
        </Row>
        <hr />
        {
          loading === true
          ? <Col s={12} className="center"><Preloader /></Col>
          : <Estrellas evaluar={this.enviarEvaluacion} />
        }
      </div>
    );
  }

  renderGraciasPorParticipar() {
    const { imagenURL, nombreMonitor, loading } = this.state;
    return (
      <div>
        <h3 style={{ textAlign: 'center', color: '#01a1dd' }}>{`Gracias ${nombreMonitor} por participar!`}</h3>
        <Row>
          <Col s={4} />
          <Col s={4}>
            <img
              className="responsive-img"
              src={imagenURL}
              alt="gracias"
            />
          </Col>
          <Col s={4} />
        </Row>
        <Row>
          <Col s={4} />
          <Col s={4} style={{ textAlign: 'center' }}>
            <Button
              floating
              large
              className="green accent-4 button-center"
              waves="light"
              onClick={this.cargarInsumos}
              style={{ margin: '0 auto' }}
              disabled={loading}
            >
              <Icon>cached</Icon>
            </Button>
          </Col>
          <Col s={4} />
        </Row>
      </div>
    );
  }

  renderIngresarNombre() {
    const { nombreMonitor, isEmptyError } = this.state;
    return (
      <div>
        <Row>
          <h2 style={{ textAlign: 'center', color: '#01a1dd' }}>
            Monitor Virtual
          </h2>
        </Row>
        <Row>
          <Col s={2} />
          <Col s={8}>
            <h4 style={{ color: '#01a1dd' }}>
              Ingresa tu nombre:
            </h4>
            <input
              type="text"
              onChange={this.onChange.bind(this)}
              value={nombreMonitor}
            />
            {
              isEmptyError
                ? (
                  <Card className="blue-grey darken-1" textClassName="white-text" title="Error">
                    Debes ingresar tu nombre para continuar
                  </Card>
                ) : null
            }
            <Button waves="orange" onClick={this.guardarNombre}>
              Continuar
              <Icon right>forward</Icon>
            </Button>
          </Col>
          <Col s={2} />
        </Row>
      </div>
    );
  }

  render() {
    const {
      error, finalizar, isStart, isFilter, filter, lotes, labels
    } = this.state;
    let componente = <Preloader />;
    if (error) {
      componente = this.renderError();
    } else if (!isStart) {
      // Captura el nombre del monitor
      componente = this.renderIngresarNombre();
    } else if (!isFilter) {
      componente = (
        <div>
          <Filters
            labels={labels}
            lotes={lotes}
            filter={filter}
            onChange={this.changeFilter}
          />
        </div>
      );
    } else if (!finalizar) {
      componente = this.renderEvaluar();
    } else {
      componente = this.renderGraciasPorParticipar();
    }
    return <div>{componente}</div>;
  }
}

export default Evaluar;
