import React, { Component } from 'react';
import { LocutorAPI } from '../../services/api/locutor-api';
import List from './list';
import {
  Row,
  Col,
  Button,
  Icon,
  Card,
  Preloader,
  Modal
} from 'react-materialize';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      lista: [],
      listaDialogo:[],
      showModal: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.listaRefinamiento = this.listaRefinamiento.bind(this);
    this.changeModal = this.changeModal.bind(this);
  }

  componentWillMount(){
    this.listaRefinamiento();
  }

  componentWillReceiveProps(){
    this.listaRefinamiento();
  }

  listaRefinamiento() {
    // LocutorAPI.getRefinement(`locutor-${this.props.autor.replace(' ', '-')}`,"").then((frase) => {
    //   this.setState({ lista: frase });
    // });
    LocutorAPI.getRefinement(`locutor-${this.props.autor.replace(' ', '-')}`,"dialog-").then((frase) => {
      this.setState({ listaDialogo: frase });
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  async handleSubmit() {
    try {
      const frase = await LocutorAPI.getSearchPhrase(this.state.value,`locutor-${this.props.autor.replace(' ', '-')}`);
      if (frase.length >= 0) {
        alert('frase encontrada: ' + frase[0].texto);
        this.props.searchPhrase(frase[0]);
      } else {
        alert('No se encontró la frase');
      }
    } catch (e) {
      console.log(e);
      console.error(`Error al recuperar la frase del servidor: ${JSON.stringify(e)}`);
      alert('error al buscar');
    }
  }

  changeModal(bandera){
    this.setState({ showModal: bandera });
  }

  render() {
    const { showModal } = this.state;
    console.log(showModal)
    return (
      <Col s={12}>
        <Row>
          <Col s={6}>
            {this.props.showList ? <input type="search" placeholder="Buscar una frase" value={this.state.value} onChange={this.handleChange}></input>: <p>Grabando.</p>}
          </Col>
          <Col s={3}>
            {this.props.showList ? <Button color='white' onClick={this.handleSubmit}> Buscar </Button>: <p></p>}
          </Col>
          <Col s={3} id="colModal">
            {(this.state.lista.length>0 || this.state.listaDialogo.length>0) && 
            this.props.showList && this.props.showListF2 
            ?<Button color='white' onClick={() => this.changeModal(true)}> Frase Prioritarias </Button>: <p>n/a</p>}
            {showModal ?
              <Modal style={{ textAlign: "center" }}
                actions={false}
                header="Listado de frases en refinamiento"
                open={showModal}
                id="Modal"
                options={{
                  dismissible: false
                }}
              >
                <List
                  showModal={this.changeModal}
                  lista={this.state.lista}
                  listaDialogo={this.state.listaDialogo}
                  searchPhrase={this.props.searchPhrase}
                  listaRefinamiento={this.listaRefinamiento}
                />
              </Modal> : null
            }
          </Col >
        </Row >
      </Col >
    );
  }
}

export default Search;