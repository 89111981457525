import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Col, Row, Button, Checkbox } from "react-materialize";

class Import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecion: "",
      limitar: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.listPharses = this.listPharses.bind(this);
    this.click = this.click.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.handleChek = this.handleChek.bind(this);
  }
  cancelar() {
    console.log("cancelar");
  }
  click() {
    const frase = this.state.selecion ? this.state.selecion : -1;
    if (frase !== -1) {
      this.props.showModal(false);
      this.props.searchPhrase(this.state.selecion);
    } else {
      alert(`No se selecciono una frase`);
    }
  }

  handleChange(event) {
    this.setState({ selecion: event.target.value });
  }

  handleChek() {
    this.setState({ limitar: !this.state.limitar });
    console.log(this.state.limitar);
  }

  listPharses() {
    var i = 0;
    const lis = this.props.lista.map((l, index) => {
      i++;
      if (i <= 20 || !this.state.limitar) {
        return (
          <MenuItem key={index} value={l}>
            {l.texto}
          </MenuItem>
        );
      }
    });
    return (
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        onChange={this.handleChange}
      >
        {lis}
      </Select>
    );
  }

  listPharsesDialog() {
    var i = 0;
    const lis = this.props.listaDialogo.map((l, index) => {
      i++;
      if (i <= 200 || !this.state.limitar) {
        return (
          <MenuItem key={index} value={l}>
            {l.texto}
          </MenuItem>
        );
      }
    });
    return (
      <Select
        labelId="demo-controlled-open-select-listaDialogo"
        id="demo-controlled-open-listaDialogo"
        onChange={this.handleChange}
      >
        {lis}
      </Select>
    );
  }

  render() {
    const myStyle = {
      textAlign: "right",
      bottom: "1px",
    };
    return (
      <>
        <Row>
          <hr style={{ width: "75%" }} />
          <Checkbox
            id="Checkbox_3"
            label="limitar frases"
            value="limitar"
            checked={this.state.limitar}
            onChange={this.handleChek}
          />
        </Row>
        <Row>Dialogos: {this.listPharsesDialog()}</Row>

        {//<Row>Variables: {this.listPharses()}</Row>
        }

        <Row style={myStyle}>
          <Button color="white" onClick={() => this.props.showModal(false)}>
            Cancelar
          </Button>
          {"     "}
          <Button color="white" onClick={this.click}>
            {" "}
            Aceptar{" "}
          </Button>
        </Row>
      </>
    );
  }
}

export default Import;
