import { INSTANCE } from "../";

export class UsuarioAPI {

  static getInfoAudios(userID) {
    return INSTANCE.get('/api/usuarios/usuario/info/' + userID)
    .then(response => response.data);
  }

  static getInfoLocutorAudios(userID) {    
    return INSTANCE.get('/api/usuarios/usuario/info/locutor-' + userID.toLowerCase().replace(/\s/g,'-'))
    .then(response => response.data);    
  }

}