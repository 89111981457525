import React, { Component } from "react";
import {
  Table,
  Button,
} from "react-materialize";

import { LocutorAPI } from '../../services/api/locutor-api';

const serverProtocol = process.env.REACT_APP_HOST_PROTOCOL || 'http'
const serverHost = process.env.REACT_APP_HOST_SERVER || '127.0.0.1'
const serverPort = process.env.REACT_APP_PORT_SERVER || '3003'

var serverURL = ""
if (serverProtocol === "https") {
    serverURL = `${serverProtocol}://${serverHost}`
} else {
    //Diferente de prod
    serverURL = `${serverProtocol}://${serverHost}:${serverPort}`
}


class SearchAudios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audiosTotales: null,
      value: '',
      userData: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderTable = this.renderTable.bind(this);

  }

  
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  async handleSubmit() {
    this.setState({ userData: [] });
    LocutorAPI.getSearchAudios(`locutor-vanessa`,this.state.value).then((frase) => {
      console.log(frase)
      this.setState({ userData: frase });
      this.render();
    });
    //console.log(this.state.userData);
    //alert(`error al buscar en desarrollo ${this.state.value}`);
  }

  renderTable(){
    const lis = this.state.userData.map((l,key) =>
      //<MenuItem key={index} value={l}>{l.texto}</MenuItem>
      <tr key={key}>
        <td width={100}>{l.fecha}</td>
        <td width={100}>{l.texto}</td>
        <td width={300}><audio controls src={`${serverURL}/api/audios/archivo/nombre/${l.nombre}`} /></td>
        <td width={50}>{l.eva||"N/A"}</td>

      </tr>
    );
    return(
    <Table>
    <thead>
      <tr>
        <th data-field="fecha">Fecha</th>
        <th data-field="texto">Texto</th>
        <th data-field="audio">audio</th>
        <th data-field="eval">evaluacion</th>
      </tr>
    </thead>
    <tbody>
      {lis}
    </tbody>
  </Table>
    )
  }

  render() {
    return (
      <div>
        <h4>Herramienta de busqueda de Alpha Corpus</h4>
        <input
          type="search"
          placeholder="Buscar un audio"
          value={this.state.value}
          onChange={this.handleChange}
        ></input>
        <Button color="white" onClick={this.handleSubmit}>
          {" "}
          Buscar{" "}
        </Button>
        {this.state.userData.length > 0 ? this.renderTable():"NoData"}

      </div>
    );
  }
}
export default SearchAudios;
