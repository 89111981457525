import React, { useState } from 'react';
import { DeleteAPI } from '../../services/api/delete-api'
import {
  Grid,
  Button,
  LinearProgress,
  Select,
  FormHelperText,
  FormControl,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import TablaFrases from './tabla';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function NativeSelects() {
  const classes = useStyles();
  const [query, setQuery] = useState({
    dialog: null,
    autor: null,
    mes: null,
    anio: null
  });
  const [frasesBorrar, setFrasesBorrar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [borrar, setBorrar] = useState(null);
  const [confirmar, setConfirmar] = useState(false);
  console.log(query.dialog)
  console.log(query.autor)
  console.log(query.mes)
  console.log(query.anio)

  const handleChange = (event) => {
    const name = event.target.name;
    setQuery({
      ...query,
      [name]: event.target.value
    });
  };

  const handleClick = () => {
    console.log("se hizo click en el boton");
    setLoading(true);
    iniciarDatos();
  }

  const iniciarDatos = () => {
    const { dialog, autor, mes, anio } = query;
    console.log('se ejecuta la consulta');
    DeleteAPI.frasesSinAudio(dialog, autor, mes, anio)
      .then((res) => {
        console.log(`respuesta:`, res.updateIds);
        setLoading(false);
        setFrasesBorrar(res.updateIds);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      })
  }

  const eliminar = (frase) => {
    console.log(`click en eliminar un registro`, frase.texto)
    setLoading(true)
    DeleteAPI.updateFrase(frase._id)
      .then((res) => {
        const frasesFiltradas = frasesBorrar.filter(newfrase => newfrase._id !== frase._id)
        setBorrar(null)
        setLoading(false)
        setFrasesBorrar(frasesFiltradas)
      })
      .catch((err) => {
        setError(true)
        setLoading(false)
      })
  }

  return (

    <Grid id="gridContainer" container spacing={1}>
      <Grid item xs={12} sm={3} md={3}>
        <h4>Filtros</h4>
        <br />
        <br />
        <FormControl required className={classes.formControl}>
          <Select
            native
            value={query.dialog}
            onChange={handleChange}
            name="dialog"
            inputProps={{
              id: 'dialog-native-required',
            }}
          >
            <option aria-label="None" value="" />
            <option value={"dialog"}>Frase</option>
            <option value={"static"}>Palabra</option>
          </Select>
          <FormHelperText>Dialogo</FormHelperText>

          <Select
            native
            value={query.autor}
            onChange={handleChange}
            name="autor"
            inputProps={{
              id: 'autor-native-required',
            }}
          >
            <option aria-label="None" value="" />
            <option value={"locutor-vanessa"}>Vanessa I</option>
            <option value={"locutor-alexander-periche"}>Alexander P</option>
            <option value={"locutor-lorena"}>Lorena B</option>
            <option value={"locutor-qa"}>Usuario QA</option>
           
          </Select>
          <FormHelperText>Locutor</FormHelperText>

          <Select
            native
            value={query.anio}
            onChange={handleChange}
            name="anio"
            inputProps={{
              id: 'anio-native-required',
            }}
          >
            <option aria-label="None" value="" />
            <option value={2021}>2021</option>
            <option value={2022}>2022</option>
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
          </Select>
          <FormHelperText>Año</FormHelperText>

          <Select
            native
            value={query.mes}
            onChange={handleChange}
            name="mes"
            inputProps={{
              id: 'mes-native-required',
            }}
          >
            <option aria-label="None" value="" />
            <option value={1}>Enero</option>
            <option value={2}>Febrero</option>
            <option value={3}>Marzo</option>
            <option value={4}>Abril</option>
            <option value={5}>Mayo</option>
            <option value={6}>Junio</option>
            <option value={7}>Julio</option>
            <option value={8}>Agosto</option>
            <option value={9}>Septiembre</option>
            <option value={10}>Octubre</option>
            <option value={11}>Noviembre</option>
            <option value={12}>Diciembre</option>
          </Select>
          <FormHelperText>Mes</FormHelperText>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            endIcon={<Icon>send</Icon>}
            disabled={!query.anio || !query.dialog || !query.mes || !query.autor}
            disableElevation
          >
            Consultar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <div>
          {/* <Button
            variant="outlined"
            color="secondary"
            onClick={eliminar}
            startIcon={<DeleteIcon />}
            disableElevation
          >
            Eliminar toda la tabla
          </Button> */}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        {
          loading
            ? <LinearProgress />
            : <TablaFrases
              frasesBorrar={frasesBorrar}
              eliminar={setBorrar}
            />
        }
      </Grid>
      {
        error
          ? <Alert severity="error">Error al ejecutar la consulta</Alert>
          : null
      }
      {
        borrar
          ? (<Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={borrar}
          >
            <DialogTitle id="confirmation-dialog-title">Proceda con ciudado</DialogTitle>
            <DialogContent >
              {`Realmente desea eliminar la frase: 
              ${borrar.texto || ""}`}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setBorrar(null)} color="primary">
                Cancelar
              </Button>
              <Button onClick={()=> eliminar(borrar)} color="primary">
                SI
              </Button>
            </DialogActions>
          </Dialog>)
          : null
      }
    </Grid>
  );
}
