import { connect } from 'react-redux';
import { Banner } from './index';
// import { setInfoPanel, showInfoPanel, hideInfoPanel } from '../../../redux/actions/actions';

const mapStateToProps = (state) => {
  return {
    speaker: state.bannerPanel.speaker,
    showPanel: state.bannerPanel.showPanel
  };
};

/* const mapDispatchToProps = dispatch => ({
  showInfoPanel: () => dispatch(showInfoPanel()),
  hideInfoPanel: () => dispatch(hideInfoPanel()),
  setInfoPanel: data => dispatch(setInfoPanel(data)),
  test: () => console.log('jaja piberdei')
});  */

const BannerContainer = connect(
  mapStateToProps,
  null
)(Banner);

export default BannerContainer;
