import { TYPES } from '../actions/actionTypes';
import { initialState } from '../model/initialStore';

export default (state = initialState.rankPanel, action) => {
  switch (action.type) {
    case TYPES.PERSONAL_RANK.SHOW:
      return { showRank: true };
    case TYPES.PERSONAL_RANK.HIDE:
      return { showRank: false };
    case TYPES.PERSONAL_RANK.SET:
      return {
        showRank: true,
        name: action.name,
        score: action.score
      };
    default:
      return state;
  }
};
