import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-materialize";
import './style.css';

export class Banner extends Component {

  completarCadenaTiempo(cantidad){    
    cantidad = cantidad === null ? 0 : cantidad;
    return cantidad < 10 ? `0${cantidad}` : cantidad;
  }

  renderInfoLocutor() {
    let nombre = typeof this.props.speaker.name === 'undefined' ? 'Pendiente' : this.props.speaker.name;
    let calificacion = (this.props.speaker.score === null || isNaN(this.props.speaker.score)) ? 'Pendiente' : `${this.props.speaker.score}/10`;

    let tiempoTotal = this.completarCadenaTiempo(this.props.speaker.time.total.horas);
    tiempoTotal = tiempoTotal + ':';
    tiempoTotal = tiempoTotal + this.completarCadenaTiempo(this.props.speaker.time.total.minutos);
    tiempoTotal = tiempoTotal + ':';
    tiempoTotal = tiempoTotal + this.completarCadenaTiempo(this.props.speaker.time.total.segundos);

    let tiempoCalificado = this.completarCadenaTiempo(this.props.speaker.time.scored.horas);
    tiempoCalificado = tiempoCalificado + ':';
    tiempoCalificado = tiempoCalificado + this.completarCadenaTiempo(this.props.speaker.time.scored.minutos);
    tiempoCalificado = tiempoCalificado + ':';
    tiempoCalificado = tiempoCalificado + this.completarCadenaTiempo(this.props.speaker.time.scored.segundos);

    return (<Row style={{textAlign:'center', marginBottom:'0'}}>
      <Col s={12}>
        <Row>
          <Col s={2} style={{fontWeight:'bold'}}>Grabaciones</Col>
          <Col s={1} style={{color:'darkcyan'}}>Calificadas</Col>
          <Col s={1} style={{color:'lightslategray'}}>{this.props.speaker.numAudios.scored}</Col>
          <Col s={2} style={{fontWeight:'bold'}}>Tiempo</Col>
          <Col s={1} style={{color:'darkcyan'}}>Calificado</Col>
          <Col s={1} style={{color:'lightslategray'}}>{tiempoCalificado}</Col>                   
          <Col s={2} style={{fontWeight:'bold'}}>Nombre</Col>
          <Col s={1} style={{fontWeight:'bold'}}>Calificación</Col>
          <Col s={1} style={{fontWeight:'bold'}}>Ranking</Col>
        </Row>
        <Row style={{marginBottom:'10px'}}>         
          <Col s={2}></Col>
          <Col s={1} style={{color:'darkslategray'}}>Total</Col>
          <Col s={1} style={{color:'lightslategray'}}>{this.props.speaker.numAudios.total}</Col>   
          <Col s={2}></Col>
          <Col s={1} style={{color:'darkslategray'}}>Total</Col>
          <Col s={1} style={{color:'lightslategray'}}>{tiempoTotal}</Col>                  
          <Col s={2}>{nombre}</Col>
          <Col s={1}>{calificacion}</Col>
          <Col s={1} style={{color:'green'}}><Link to={process.env.REACT_APP_RUTA_RANKING||'/ranking'}>Ver</Link></Col>      
        </Row>      
      </Col>    
    </Row>);
  }

  renderComponente(){  
    let componente = null;
    if (this.props.showPanel) {      
      componente = this.renderInfoLocutor();
    }
    return componente;
  }

  render() {
    let componente = this.renderComponente();
    return (
      <Row className='banner' style={{borderBottomStyle:'double'}}>   
        <Col s={12} >
          {componente}
        </Col>
      </Row>
    );
  }
}
