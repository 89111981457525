/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Icon,
  Card,
  Collapsible,
  CollapsibleItem,
  Collection,
  CollectionItem,
  Chip,
  Preloader,
  Textarea
} from 'react-materialize';
import Uploader from './uploader';
import { UsuarioAPI } from '../../services/api/usuario-api';
import { PlataformaAPI } from '../../services/api/plataforma-api';
import { AudioAPI } from '../../services/api/audio-api';

const mimeType = process.env.REACT_APP_MIME_WAV_AUDIOS || 'audio/x-wav';
const LOTE_MONITORES_PLATAFORMA =process.env.LOTE_MONITORES_PLATAFORMA || 'monitor-virtual';

class Plataforma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autor: '',
      isEmptyError: false,
      isLabelError: false,
      originalLabel: LOTE_MONITORES_PLATAFORMA,
      currentSentence: '',
      selectedSentence: '',
      newSentence: '',
      isAcordeon: false,
      isStart: false,
      isSentences: false,
      isLoading: false,
      immutableLabels: [],
      immutableSentences: [],
      savedSentences: [],
      labels: [],
      selectedBatch: null,
      sentences: [],
      isFiles: false,
      files: [],
      fileState: {},
      audioUrl: '',
      audioDuration: 0
    };
    this.guardarNombre = this.guardarNombre.bind(this);
    this.renderPlataforma = this.renderPlataforma.bind(this);
    this.renderBotonRegresar = this.renderBotonRegresar.bind(this);
    this.renderListaFiltros = this.renderListaFiltros.bind(this);
    this.renderFrases = this.renderFrases.bind(this);
    this.renderIngresarNuevaFrase = this.renderIngresarNuevaFrase.bind(this);
    this.toogleAcordeon = this.toogleAcordeon.bind(this);
    this.toogleLabel = this.toogleLabel.bind(this);
    this.buscarFrases = this.buscarFrases.bind(this);
    this.actualizarLista = this.actualizarLista.bind(this);
    this.mostrarFiltrar = this.mostrarFiltrar.bind(this);
    this.agregarArchivos = this.agregarArchivos.bind(this);
    this.eliminarArchivo = this.eliminarArchivo.bind(this);
    this.enviarArchivo = this.enviarArchivo.bind(this);
    this.configurarAudioInvisible = this.configurarAudioInvisible.bind(this);
    this.enviarFormulario = this.enviarFormulario.bind(this);
    this.saveNewSentence = this.saveNewSentence.bind(this);
    this.audioInvisible = React.createRef();
  }

  componentDidMount() {
    this.iniciarDatos();
  }

  onChange(event) {
    this.setState({
      autor: event.target.value,
      isEmptyError: false
    });
  }

  async iniciarDatos() {
    const { originalLabel } = this.state;
    const { status, lotes } = await PlataformaAPI.getBatches();
    if (status === 0) {
      const firstlabels = lotes.filter(l => l.etiqueta.includes(originalLabel));
      this.setState({
        labels: firstlabels,
        immutableLabels: firstlabels
      });
    } else {
      this.setState({
        isLabelError: true
      });
    }
  }

  toogleAcordeon() {
    const { isAcordeon } = this.state;
    this.setState({
      isAcordeon: !isAcordeon
    });
  }

  toogleLabel(lote) {
    let { labels, selectedBatch } = this.state;
    const puntero = labels.indexOf(lote);

    // Si el elemento está en la lista lotes
    if (puntero !== -1) {
      // Quito de lotes
      labels = labels.filter(l => l.nombre !== lote.nombre);
      // Selecciono
      selectedBatch = lote;
    } else {
      // Pongo en labels
      labels.push(lote);
      // Quito de seleccion
      selectedBatch = null;
    }
    this.setState({
      labels,
      selectedBatch
    });
  }

  recuperaInfoAudios() {
    // eslint-disable-next-line react/prop-types
    const { showInfoPanel, setInfoPanel } = this.props;
    const { autor } = this.state;
    UsuarioAPI.getInfoLocutorAudios(autor).then((response) => {
      if (response.status === 0) {
        showInfoPanel();
        setInfoPanel(response.info);
      }
    });
  }

  guardarNombre() {
    const { autor } = this.state;
    if (autor !== '') {
      this.setState({
        isStart: true,
        isAcordeon: true
      }, this.recuperaInfoAudios());
    } else {
      this.setState({
        isEmptyError: true
      });
    }
  }

  buscarFrases() {
    const { selectedBatch } = this.state;
    this.setState({
      isLoading: true
    }, () => {
      PlataformaAPI.getPhrasesByBatch(selectedBatch).then((response) => {
        const { status, frases } = response;
        if (status === 0) {
          // Ok
          this.setState({
            isSentences: true,
            sentences: frases,
            isLoading: false,
            immutableSentences: frases
          });
        }
      });
    });
  }

  actualizarNuevaFrase(event) {
    const { value } = event.target;
    if (value !== '') {
      this.setState({
        newSentence: value
      });
    }
  }

  saveNewSentence() {
    const { newSentence, autor, selectedBatch } = this.state;
    const autorLimpio = `monitor-${autor.trim().toLowerCase().replace(' ', '-')}`;
    const fraseLista = {
      texto: newSentence,
      etiquetas: selectedBatch.etiqueta
    };
    // Método para guardar una nueva frase en el lote
    PlataformaAPI.saveNewSentence(autorLimpio, selectedBatch.nombre, fraseLista)
      .then((response) => {
        const { status, frases } = response;
        if (status === 0) { // ok
          const { immutableSentences } = this.state;
          let nuevaFrase = frases.filter(f => f.texto);
          nuevaFrase = Array.isArray(nuevaFrase) ? nuevaFrase[0] : nuevaFrase;          
          // Agregar nueva frase al array
          immutableSentences.push(nuevaFrase);
          const sentences = [...immutableSentences];
          this.setState({
            immutableSentences,
            sentences,
            newSentence: ''
          });
        }
      });
  }

  actualizarLista(event) {
    const { immutableSentences } = this.state;
    const { value } = event.target;
    let filteredSentences = immutableSentences;
    if (value !== '') {
      const regex = new RegExp(value, 'i');
      filteredSentences = immutableSentences.filter(s => regex.test(s.texto));
    }
    this.setState({
      currentSentence: value,
      sentences: filteredSentences
    });
  }

  agregarArchivos(nuevosArchivos) {
    const { files, fileState } = this.state;
    const estadosNuevosArchivos = {};
    nuevosArchivos.forEach((archivo) => {
      if (archivo.type === mimeType || archivo.type === 'audio/wav') {
        estadosNuevosArchivos[archivo.path] = 'pendiente';
      } else {
        estadosNuevosArchivos[archivo.path] = 'formato-invalido';
      }
    });
    // Agrega el estado de los nuevos archivos al state del componente
    Object.assign(fileState, estadosNuevosArchivos);
    this.setState({
      files: nuevosArchivos.concat(files),
      fileState,
      isFiles: true
    });
  }

  eliminarArchivo(archivo) {
    const { files, fileState } = this.state;
    const newFiles = files.filter(f => f.path !== archivo.path);
    delete fileState[archivo.path];
    this.setState({
      files: newFiles,
      fileState,
      isFiles: newFiles.length > 0
    });
  }

  configurarAudioInvisible(file) {
    const audioUrl = URL.createObjectURL(file);
    // Chrome's bug fix
    this.setState({
      audioUrl
    }, () => {
      this.audioInvisible.current.ondurationchange = () => {
        if (this.audioInvisible.current.duration !== Infinity) {
          this.setState({
            audioDuration: this.audioInvisible.current.duration
          }, () => this.enviarFormulario(file));
        }
      };
      this.audioInvisible.current.onloadedmetadata = () => {
        if (this.audioInvisible.current.duration !== Infinity) {
          this.setState({
            audioDuration: this.audioInvisible.current.duration
          });
        }
      };
    });
  }

  enviarArchivo(file) {
    const { fileState } = this.state;
    // validar

    // Cambiar a estado enviando
    fileState[file.path] = 'cargando';
    this.setState({
      fileState
    }, () => {
      this.configurarAudioInvisible(file);
    });// Configurar audio invisible
  }

  enviarFormulario(file) {
    const {
      selectedSentence,
      autor,
      audioDuration,
      savedSentences,
      fileState
    } = this.state;

    // Crea un formulario ----------
    const form = new FormData();
    // Adjunta el audio
    form.append('file', file, 'audio');
    // Adjunta el id de la frase
    form.append('id', selectedSentence._id);
    // Adjunta el id del locutor
    form.append('autor', `monitor-${autor.trim().toLowerCase().replace(' ', '-')}`);
    // Ajunta la duración del audio
    form.append('duration', audioDuration);

    // Enviar formulario
    AudioAPI.sendAudio(form)
      .then((response) => {
        const { status } = response;
        // setTimeout(resetRecorder(), 1500);
        // Si todo OK
        if (status === 0) {
          // Cambiar estado del archivo
          fileState[file.path] = 'enviado';
          savedSentences.push(selectedSentence);
          this.setState({
            fileState,
            savedSentences
          });
        } else {
          // Todo no Ok
          // Cambiar estado del archivo
          fileState[file.path] = 'error-enviar';
          this.setState({
            fileState
          });
        }
        // this.reiniciar();
      }).catch((error) => {
        console.error(`Error al enviar el audio: ${error}`);
        // this.onError(`Error al enviar el audio: ${error}`);
      });
  }

  seleccionarFrase(frase) {
    this.setState({
      selectedSentence: frase,
      files: [],
      isFiles: false
    });
  }

  mostrarFiltrar() {
    const { immutableLabels } = this.state;
    this.setState({
      labels: immutableLabels,
      selectedBatch: null,
      sentences: [],
      currentSentence: '',
      selectedSentence: '',
      immutableSentences: [],
      isSentences: false
    });
  }

  renderIngresarNombre() {
    const { autor, isEmptyError } = this.state;
    return (
      <div>
        {Plataforma.renderTitulo()}
        <Row>
          <Col s={2} />
          <Col s={8}>
            <h4 style={{ color: '#01a1dd' }}>
              Ingresa tu nombre:
            </h4>
            <input
              type="text"
              style={{ color: 'gray' }}
              onChange={this.onChange.bind(this)}
              value={autor}
            />
            {
             isEmptyError === true
               ? (
                 <Card className="blue-grey darken-1" textClassName="white-text" title="Error">
                  Debes ingresar tu nombre para continuar
                 </Card>
               )
               : null
            }
            <Button waves="orange" onClick={this.guardarNombre}>
              Guardar
              <Icon right>forward</Icon>
            </Button>
          </Col>
          <Col s={2} />
        </Row>
      </div>
    );
  }

  static renderLoading() {
    return (
      <Col
        s={12}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Preloader flashing />
      </Col>
    );
  }

  static renderTitulo() {
    return (
      <Row>
        <h2 style={{ textAlign: 'center', color: '#01a1dd' }}>
          Plataforma
        </h2>
      </Row>
    );
  }

  renderTituloAcordeon() {
    const { isAcordeon } = this.state;
    return (
      <Row style={{ display: 'contents' }} onClick={this.toogleAcordeon}>
        <Col s={4} style={{ color: '#01a1dd' }}>
          <h5>Seleccionar Lote</h5>
        </Col>
        <Col s={4} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Button
            floating
            large
            onClick={this.toogleAcordeon}
          >
            <i className="material-icons" style={{ width: 'inherit' }}>
              {isAcordeon ? 'eject' : 'arrow_drop_down_circle'}
            </i>
          </Button>
        </Col>
        <Col s={4} />
      </Row>
    );
  }

  renderFiltrosEtiquetas() {
    const { labels, selectedBatch } = this.state;
    return (
      <Row>
        <Col s={12}>
          <Collapsible>
            <CollapsibleItem
              expanded
              header={this.renderTituloAcordeon()}
            >
              <Row style={{ borderStyle: 'dotted' }}>
                <Col s={6}>
                  <h5 style={{ textAlign: 'center', color: '#01a1dd' }}>
                    Disponibles
                  </h5>
                  {labels.map((label) => {
                    return (
                      <Row key={label.nombre} style={{ marginLeft: '.50rem' }}>
                        <Button waves="light" onClick={this.toogleLabel.bind(this, label)}>
                          {label.nombre}
                          <Icon right>
                            help
                          </Icon>
                        </Button>
                      </Row>
                    );
                  })}
                </Col>
                <Col s={6} style={{ borderLeftStyle: 'dotted' }}>
                  <h5 style={{ textAlign: 'center', color: '#01a1dd' }}>
                    Seleccionado
                  </h5>
                  {/* Renderizar el lote seleccionado */
                  selectedBatch !== null
                    ? (
                      <Row key={selectedBatch.nombre} style={{ marginLeft: '.50rem' }}>
                        <Button
                          waves="light"
                          onClick={this.toogleLabel.bind(this, selectedBatch)}
                          className="red"
                        >
                          {selectedBatch.nombre}
                          <Icon right>
                            check_circle
                          </Icon>
                        </Button>
                      </Row>
                    ) : null
                  }
                </Col>
              </Row>
              <Row>
                <Col s={4} />
                <Col
                  s={4}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                  }}
                >
                  <Button
                    disabled={selectedBatch === null}
                    onClick={this.buscarFrases}
                    className="orange accent-4"
                  >
                    Consultar
                    <Icon right>
                      search
                    </Icon>
                  </Button>
                </Col>
                <Col s={4} />
              </Row>
            </CollapsibleItem>
          </Collapsible>
        </Col>
      </Row>
    );
  }

  renderListaFiltros() {
    const { selectedBatch } = this.state;
    return (
      <Row>
        <Col s={12} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

          <Chip
            key={selectedBatch.nombre}
            style={{ background: '#01a1dd', color: 'white' }}
          >
            <h6>
              { selectedBatch.nombre }
            </h6>
          </Chip>

        </Col>
      </Row>
    );
  }

  renderBotonRegresar() {
    return (
      <Row>
        <Col m={4} s={12}>
          <Button
            onClick={this.mostrarFiltrar}
            waves="orange"
          >
            Regresar
            <Icon left>
              arrow_back
            </Icon>
          </Button>
        </Col>
      </Row>
    );
  }

  renderUploader() {
    const {
      selectedSentence,
      files,
      fileState,
      isFiles,
      audioUrl
    } = this.state;
    return (
      <Row>
        <Col m={2} s={0} />
        <Col m={8} s={12}>
          <Row>
            {selectedSentence === '' || selectedSentence.texto === ''
              ? null
              : (
                <div>
                  <h4 style={{ color: '#01a1dd' }}>Frase</h4>
                  <h5 style={{ color: 'gray' }}>{selectedSentence.texto}</h5>
                  <div>
                    <audio
                      controls
                      style={{ display: 'none' }}
                      src={audioUrl}
                      ref={this.audioInvisible}
                    />
                  </div>
                  <Uploader
                    archivos={files}
                    estados={fileState}
                    isArchivo={isFiles}
                    agregarArchivos={this.agregarArchivos}
                    eliminarArchivo={this.eliminarArchivo}
                    enviarArchivo={this.enviarArchivo}
                  />
                </div>
              )
            }
          </Row>
        </Col>
      </Row>
    );
  }

  renderIngresarNuevaFrase() {
    const { newSentence } = this.state;
    return (
      <Row>
        <Col s={12}>
          <Collection
            header="Ingresar Frase"
            style={{ color: '#01a1dd' }}
          >
            <Row>
              <Col s={8}>
                <Textarea
                  s={10}
                  icon="mode_edit"
                  label="Ingresar nueva frase..."
                  onChange={this.actualizarNuevaFrase.bind(this)}
                  value={newSentence}
                />
              </Col>
              <Col s={4}>
                <Button waves="light" onClick={this.saveNewSentence}>
                  Guardar
                  <Icon right>
                    send
                  </Icon>
                </Button>
              </Col>
            </Row>
          </Collection>
        </Col>
      </Row>
    );
  }

  renderFrases() {
    const {
      sentences,
      currentSentence,
      selectedSentence,
      savedSentences
    } = this.state;
    return (
      <Row>
        <Col s={12}>
          <Collection
            header="Seleccionar Frase"
            style={{ color: '#01a1dd' }}
          >
            <Row>
              <Textarea
                s={10}
                icon="search"
                label="Buscar frase..."
                onChange={this.actualizarLista.bind(this)}
                value={currentSentence}
              />
            </Row>
            <Row>
              {
                sentences.map((sentence) => {
                  let colorFrase = null;
                  const isFraseGuardada = savedSentences.filter(s => s._id === sentence._id);
                  if (selectedSentence._id === sentence._id) {
                    colorFrase = 'orange accent-4';
                  } else if (isFraseGuardada.length > 0) {
                    colorFrase = 'light-green accent-4';
                  }
                  return (
                    <CollectionItem
                      key={sentence._id}
                      style={{ color: '#000000', cursor: 'pointer' }}
                      onClick={this.seleccionarFrase.bind(this, sentence)}
                      className={colorFrase}
                      icon="send"
                    >
                      {sentence.texto}
                    </CollectionItem>
                  );
                })
              }
            </Row>
          </Collection>
        </Col>
      </Row>
    );
  }

  renderSubirAudios() {
    return (
      <div>
        <Row>
          <Col m={6} s={12}>
            <Row>
              { this.renderBotonRegresar() }
            </Row>
            <Row>
              { this.renderUploader() }
            </Row>
          </Col>
          <Col m={6} s={12}>
            <Row>
              { this.renderListaFiltros() }
            </Row>
            <Row>
              { this.renderIngresarNuevaFrase() }
            </Row>
            <Row>
              { this.renderFrases() }
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  renderPlataforma() {
    const { isSentences, isLoading } = this.state;
    let componenete = null;
    if (!isSentences && !isLoading) {
      componenete = this.renderFiltrosEtiquetas();
    } else if (!isSentences && isLoading) {
      componenete = Plataforma.renderLoading();
    } else if (isSentences && !isLoading) {
      componenete = this.renderSubirAudios();
    }
    return (
      <div>
        { Plataforma.renderTitulo() }
        { componenete }
      </div>
    );
  }

  render() {
    const { isStart } = this.state;
    let componente = null;
    if (isStart) {
      componente = this.renderPlataforma();
    } else {
      componente = this.renderIngresarNombre();
    }
    return componente;
  }
}

export default Plataforma;
