export const TYPES = {
  PERSONAL_RANK: {
    SHOW: 'SHOW_PERSONAL_RANK',
    HIDE: 'HIDE_PERSONAL_RANK',
    SET: 'SET_PERSONAL_RANK'
  },
  PANEL_MESSAGE: {
    CLEAN: 'PANEL_MESSAGE_CLEAN',
    MESSAGE: 'PANEL_MESSAGE_MESSAGE'
  },
  PANEL_INFO: {
    SHOW: 'PANEL_INFO_SHOW',
    HIDE: 'PANEL_INFO_HIDE',
    SET: 'PANEL_INFO_SET',
    SET_USER_ID: 'PANEL_INFO_SET_USER_ID'
  }
}