import axios from 'axios';

const serverProtocol = process.env.REACT_APP_HOST_PROTOCOL || 'http';
const serverHost = process.env.REACT_APP_HOST_SERVER || 'hefesto.servex.local';
const serverPort = process.env.REACT_APP_PORT_SERVER || '3001';
const serverURL = serverProtocol === 'https' ? `${serverProtocol}://${serverHost}`: `${serverProtocol}://${serverHost}:${serverPort}`;

const INSTANCE = axios.create({
  baseURL: serverURL,
  headers: { 'Content-Type': 'application/json' }
});

export {
  serverURL,
  INSTANCE
};