import React, { useState } from 'react';
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';


export default function TablaFrases({
  frasesBorrar,
  eliminar,
}) {

  return (
    <>
      {
        frasesBorrar.length > 0 ? (
          <div
          style={{maxHeight: '300px', overflowY:'scroll'}}
          >
          <table className="table">
            <thead>
              <tr>
                <th>Texto</th>
                <th>Mes</th>
                <th>Año</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
            {frasesBorrar.map(itemCategoria => 
              <tr key={itemCategoria._id}>
                <td>{itemCategoria.texto}</td>
                <td>{itemCategoria.mes}</td>
                <td>{itemCategoria.anio}</td>
                <td>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => eliminar(itemCategoria)}
                    startIcon={<DeleteIcon />}
                    disableElevation
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
              )
            }
            </tbody>
          </table>
          </div>)
          : (
            <div>
              <Alert severity="error">Ingrese una consulta valida</Alert>
            </div>
          )
      }
    </>
  );
}