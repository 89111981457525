import { TYPES } from '../actions/actionTypes';
import { initialState } from '../model/initialStore';

export default (state = initialState.infoPanel, action) => {
  switch (action.type) {
    case TYPES.PANEL_INFO.CLEAN:
      return Object.assign({}, state, initialState.speaker);
    case TYPES.PANEL_INFO.SHOW:
      return Object.assign({}, state, {
        showPanel: action.showPanel
      });
    case TYPES.PANEL_INFO.SET:
      return Object.assign({}, state, {
        speaker: {
          name: action.speaker.nombre,
          score: action.speaker.calificacionPromedio,
          time: {
            total: action.speaker.tiempoTotal,
            scored: action.speaker.tiempoCalificado
          },
          numAudios: {
            total: action.speaker.numeroAudiosTotal,
            scored: action.speaker.numeroAudiosCalificados,
            pending: action.speaker.numeroAudiosPendientesTiempo
          }
        }
      });
    case TYPES.PANEL_INFO.SET_USER_ID:
      return Object.assign({}, state, {
        userId: action.userID
      });
    default:
      return state;
  }
};
