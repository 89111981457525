import React, { Component } from 'react';
import {
  Button, Icon, Row, Col
} from 'react-materialize';

class Estrella extends Component {
  constructor(props) {
    super(props);
    this.clickEstrella = this.clickEstrella.bind(this);
  }

  clickEstrella() {
    this.props.funcion(this.props.n);
  }

  render() {
    return (
      <Col s={1}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={this.clickEstrella}
        >
          {this.props.activa
            ? <Icon>favorite</Icon>
            : <Icon>favorite_border</Icon>
              }
        </div>
      </Col>
    );
  }
}

export class Estrellas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluacion: -1,
      activas: [
        false, // 1
        false, // 2
        false, // 3
        false, // 4
        false, // 5
        false, // 6
        false, // 7
        false, // 8
        false, // 9
        false// 10
      ]
    };
    this.activar = this.activar.bind(this);
    this.cargarInsumos = this.cargarInsumos.bind(this);
  }

  activar(identificador) {
    const nuevaValoracion = identificador + 1;
    const tempActivas = [];
    for (let i = 0; i <= 9; i++) {
      if (i <= identificador) {
        tempActivas.push(true);
      } else {
        tempActivas.push(false);
      }
    }
    this.setState({
      evaluacion: nuevaValoracion,
      activas: tempActivas
    });
  }

  evaluar(e) {
    // Si no se han calificado los
    // corazones
    if (this.state.evaluacion === -1) {
      // Indicar al usuario el error
      console.error('Primero debes calificar los corazones.');
    } else {
      // Continuar con la evaluación
      this.props.evaluar(this.state.evaluacion, this.cargarInsumos);
    }
  }

  cargarInsumos() {
    this.setState({
      evaluacion: -1,
      activas: [
        false, // 1
        false, // 2
        false, // 3
        false, // 4
        false, // 5
        false, // 6
        false, // 7
        false, // 8
        false, // 9
        false// 10
      ]
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col s={1} />
          <Estrella
            n={0}
            activa={this.state.activas[0]}
            funcion={this.activar}
          />
          <Estrella
            n={1}
            activa={this.state.activas[1]}
            funcion={this.activar}
          />
          <Estrella
            n={2}
            activa={this.state.activas[2]}
            funcion={this.activar}
          />
          <Estrella
            n={3}
            activa={this.state.activas[3]}
            funcion={this.activar}
          />
          <Estrella
            n={4}
            activa={this.state.activas[4]}
            funcion={this.activar}
          />
          <Estrella
            n={5}
            activa={this.state.activas[5]}
            funcion={this.activar}
          />
          <Estrella
            n={6}
            activa={this.state.activas[6]}
            funcion={this.activar}
          />
          <Estrella
            n={7}
            activa={this.state.activas[7]}
            funcion={this.activar}
          />
          <Estrella
            n={8}
            activa={this.state.activas[8]}
            funcion={this.activar}
          />
          <Estrella
            n={9}
            activa={this.state.activas[9]}
            funcion={this.activar}
          />
          <Col s={1} />
        </Row>
        <Row>
          <Col s={1} />
          <Col s={1}>1</Col>
          <Col s={1}>2</Col>
          <Col s={1}>3</Col>
          <Col s={1}>4</Col>
          <Col s={1}>5</Col>
          <Col s={1}>6</Col>
          <Col s={1}>7</Col>
          <Col s={1}>8</Col>
          <Col s={1}>9</Col>
          <Col s={1}>10</Col>
          <Col s={1} />
        </Row>
        <Row>
          <Col s={4} />
          <Col s={4} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            {(this.state.evaluacion !== -1)
              ? (
                <Button
                  className="deep-orange accent-4 button-center"
                  waves="light"
                  onClick={e => this.evaluar(e)}
                >
                                  Evaluar
                  <Icon right>gavel</Icon>
                </Button>
              )
              : null
                      }
          </Col>
          <Col s={4} />
        </Row>
      </div>
    );
  }
}
