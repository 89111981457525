/* eslint-disable react/jsx-filename-extension */
/* global window */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Row, Col, Card, Button, Icon
} from 'react-materialize';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import BoxSelector from './BoxSelector';

import 'react-day-picker/lib/style.css';
import './filter.css';

const Filters = ({
  filter, lotes, labels, onChange
}) => {
  const [form, setForm] = useState(filter);

  const { range, gte, lte } = { range: 'range', gte: 'gte', lte: 'lte' };

  const typeDateList = [
    { id: range, text: 'Rango' },
    { id: gte, text: 'Desde' },
    { id: lte, text: 'Hasta' }
  ];

  const format = 'DD/MM/YYYY';

  const {
    from, to, tags, batch, typeDate
  } = form;

  const filtered = (
    from === undefined
    && to === undefined
    && tags.length === 0
    && batch.length === 0
  );

  const dayPickerProps = {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    weekdaysShort: [
      'Lu',
      'Ma',
      'Mi',
      'Ju',
      'Vi',
      'Sa',
      'Do'
    ],
    disabledDays: { after: new Date() }
  };

  let toRef;
  let input = '';

  const changeForm = (id, value, id2 = false, value2 = false) => {
    const newForm = Object.assign({}, form);
    newForm[id] = value;
    if (id2 !== false && value2 !== false) {
      newForm[id2] = value2;
    }
    setForm(newForm);
  };

  const selector = typeDateList.map(item => (
    <Col
      key={item.id}
      className={
        `center grey ${typeDate === item.id ? 'darken-1 white-text active' : 'lighten-2'}`
      }
      l={4}
      s={12}
      onClick={() => { changeForm('typeDate', item.id); }}
    >
      {item.text}
    </Col>
  ));

  switch (typeDate) {
    case range:
      input = (
        <Row className="InputFromToWrapper">
          <Col l={6} s={12} className="InputFromTo">
            <DayPickerInput
              key="from-range"
              format={format}
              formatDate={formatDate}
              parseDate={parseDate}
              value={from}
              onDayChange={(date) => { changeForm('from', date); }}
              placeholder="Desde DD/MM/YYYY"
              dayPickerProps={{
                ...dayPickerProps,
                selectedDays: [from, { from, to }],
                disabledDays: to === undefined ? { after: new Date() } : { after: to },
                toMonth: to,
                numberOfMonths: 2,
                modifiers: { start: from, end: to },
                onDayClick: () => {
                  const ref = toRef;
                  window.setTimeout(() => {
                    ref.getInput().focus();
                  }, 50);
                }
              }}
            />
          </Col>
          <Col l={6} s={12} className="InputFromTo-to">
            <DayPickerInput
              ref={(el) => { toRef = el; }}
              key="to-range"
              format={format}
              formatDate={formatDate}
              parseDate={parseDate}
              value={form.to}
              onDayChange={(date) => { changeForm('to', date); }}
              placeholder="Hasta DD/MM/YYYY"
              inputProps={{ disabled: from === undefined }}
              dayPickerProps={{
                ...dayPickerProps,
                selectedDays: [from, { from, to }],
                disabledDays: { before: from, after: new Date() },
                month: to,
                fromMonth: from,
                numberOfMonths: 2,
                modifiers: { start: from, end: to }
              }}
            />
          </Col>
        </Row>
      );
      break;
    case gte:
      input = (
        <DayPickerInput
          key="from"
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          value={from}
          onDayChange={(date) => { changeForm('from', date); }}
          placeholder="Desde DD/MM/YYYY"
          dayPickerProps={dayPickerProps}
        />
      );
      break;
    case lte:
      input = (
        <DayPickerInput
          key="to"
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          value={to}
          onDayChange={(date) => { changeForm('to', date); }}
          placeholder="Hasta DD/MM/YYYY"
          dayPickerProps={dayPickerProps}
        />
      );
      break;
    default:
      input = 'Debe seleccionar: Rango, Desde o Hasta';
  }


  return (
    <Row>
      <h3 style={{ textAlign: 'center', color: '#01a1dd' }}>
          Filtros
      </h3>
      <Col l={12} m={12} s={12}>
        <Card title="Fecha">
          <Row className="valign-wrapper">
            <Col l={5} m={4} s={12} className="selector-type-date">
              {selector}
            </Col>
            <Col l={7} m={8} s={12} className="center input-date">
              {input}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col m={6} s={12}>
        <Card title="Lotes">
          <BoxSelector
            data={lotes}
            object={{ id: '_id', text: 'nombre' }}
            onChange={(changed) => {
              const tagsLotes = changed.slice().reduce((partial, current) => {
                let newPartial = partial;
                const idLote = '_id';
                const currentLote = lotes.find(item => item[idLote] === current);
                if (currentLote !== undefined) {
                  newPartial = newPartial.concat(currentLote.etiqueta);
                }
                return newPartial;
              }, tags);
              changeForm('batch', changed, 'tags', tagsLotes);
            }}
            value={batch}
          />
        </Card>
      </Col>
      <Col m={6} s={12}>
        <Card title="Etiquetas">
          <BoxSelector
            data={labels}
            onChange={(changed) => {
              const lotesTags = batch.slice().reduce((partial, current) => {
                const newPartial = partial;
                let inTags = false;
                const idLote = '_id';
                const currentLote = lotes.find(item => item[idLote] === current);
                if (currentLote !== undefined) {
                  changed.every((item) => {
                    if (currentLote.etiqueta.find(etiqueta => etiqueta === item) !== undefined) {
                      inTags = true;
                    }
                    return !inTags;
                  });
                  if (inTags) {
                    newPartial.push(current);
                  }
                }
                return newPartial;
              }, []);
              changeForm('tags', changed, 'batch', lotesTags);
            }}
            value={tags}
          />
        </Card>
      </Col>
      <Col s={12}>
        <hr />
      </Col>
      <Col s={6} className="left-align">
        <Button
          waves="purple"
          className="left-align red"
          onClick={() => {
            setForm({
              from: undefined, to: undefined, tags: [], batch: [], typeDate: ''
            });
          }}
        >
            Limpiar filtros
          <Icon right>clear_all</Icon>
        </Button>
      </Col>
      <Col s={6} className="right-align">
        <Button
          waves="orange"
          className="right-align"
          onClick={() => {
            const typeDateFind = typeDateList.find(item => (item.id === typeDate));
            if (typeDate !== '' && typeDateFind !== undefined) {
              form.typeDateText = typeDateFind.text;
            }
            onChange(form);
          }}
        >
          {filtered ? 'No Filtrar' : 'Aplicar'}
          <Icon right>filter_list</Icon>
        </Button>
      </Col>
    </Row>
  );
};

Filters.propTypes = {
  filter: PropTypes.shape({
    from: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    batch: PropTypes.arrayOf(PropTypes.string).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  lotes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Filters;
