import { TYPES } from '../actions/actionTypes';
import { initialState } from '../model/initialStore';

export default (state = initialState.messagePanel, action) => {
  switch (action.type) {
    case TYPES.PANEL_MESSAGE.CLEAN:
      return Object.assign({}, state, { 
        message: '',
        code: 0 
      });          
    case TYPES.PANEL_MESSAGE.MESSAGE:    
      /*
        code: 0 -> Info
        code: 1 -> Warning
        code: 2 -> Error
        code: 3 -> Success
      */
      return { 
        showPanel: action.showPanel,
        message: action.message,
        code: action.code 
      };
    default:
      return state;
  }
};
