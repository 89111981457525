/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Icon,
  Card,
  Preloader,
  Checkbox
} from 'react-materialize';
import { LocutorAPI } from '../../services/api/locutor-api';
import { AudioAPI } from '../../services/api/audio-api';
import { UsuarioAPI } from '../../services/api/usuario-api';
import RecorderContainer from '../recorder/RecorderContainer';
import Search from '../search/search';

class Locutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      frase: '',
      autor: '',
      mensaje: 'Pronuncia la siguiente frase:',
      isStart: false,
      canRecord: false,
      isError: false,
      isEmptyError: false,
      showList: true,
      static: false
    };
    this.iniciarDatos = this.iniciarDatos.bind(this);
    this.recuperarFrase = this.recuperarFrase.bind(this);
    this.recuperaInfoAudios = this.recuperaInfoAudios.bind(this);
    this.guardarNombre = this.guardarNombre.bind(this);
    this.reiniciar = this.reiniciar.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.renderGrabarAudio = this.renderGrabarAudio.bind(this);
    this.searchPhrase = this.searchPhrase.bind(this);
    this.changeShowList = this.changeShowList.bind(this);
    this.handleChek = this.handleChek.bind(this);
  }

  searchPhrase(frase){
    this.setState({frase});
    this.renderGrabarAudio();
  }
  changeShowList(bandera){
    this.setState({showList:bandera});
  }

  componentWillUnmount() {
    const { hideInfoPanel } = this.props;
    hideInfoPanel();
  }

  handleChek() {
    this.setState({ static: !this.state.static });
    //console.log(this.state.static);
  }

  onChange(event) {
    this.setState({
      autor: event.target.value,
      isEmptyError: false
    });
  }

  onSuccess(blob, audioDuration, resetRecorder) {
    const { frase, autor } = this.state;
    const { reiniciar, onError } = this;
    // Crea un formulario
    const data1 = new FormData();
    // Adjunta el audio
    data1.append('file', blob, 'audio');
    // Adjunta el id de la frase
    data1.append('id', frase['_id']);
    // Adjunta el dni del usuario
    data1.append('autor', `locutor-${autor.replace(' ', '-')}`);
    data1.append('duration', audioDuration);

    this.setState({
      canRecord: false
    }, () => {
      AudioAPI.sendAudio(data1)
        .then(() => {
          const p1 = async () => resetRecorder();
          const p2 = async () => reiniciar();
          Promise.all([p1(), p2()]);
        }).catch(() => {
          onError('Error al enviar el audio!');
        });
    });
  }

  onError(mensaje) {
    console.error(`Ha ocurrido un error: ${mensaje}`);
    this.setState({
      isError: true
    });
  }

  reiniciar() {
    this.iniciarDatos();
  }

  iniciarDatos() {
    const { recuperarFrase, recuperaInfoAudios } = this;
    const P1 = recuperarFrase;
    const P2 = recuperaInfoAudios;
    this.setState({lista:P1})
    Promise.all([P1(), P2()]).then((resultados) => {
      // resultado 1 -> frase
      //const lista = resultados;
      const frase = resultados[0];

      if(frase.status){
        console.log(JSON.stringify(frase))
        this.setState({
          isError:true,
          mensaje:frase.error
          //canRecord: true
        });
      }else{
        this.setState({
          //lista,
          frase,
          isError:false,
          canRecord: true
        });
      }
    });
  }

  async recuperaInfoAudios() {
    const { autor } = this.state;
    const { showInfoPanel, setInfoPanel } = this.props;
    const response = await UsuarioAPI.getInfoLocutorAudios(autor);
    const { status, info } = response;
    if (status === 0) {
      showInfoPanel();
      setInfoPanel(info);
    }
    return true;
  }

  async recuperarFrase() {
    let frase = null;
    try {
      const loct = this.state.autor.trim().replace(' ', '-');
      frase = await LocutorAPI.getPhraseStatic(loct);
      //frase = this.state.static? await LocutorAPI.getPhraseStatic(loct) : await LocutorAPI.getRandPhrase();
    } catch (e) {
      console.error(`Error al recuperar la frase del servidor: ${JSON.stringify(e)}`);
      this.setState({
        mensaje: 'Error al recuperar la frase del servidor'
      });
    }
    return frase;
  }

  guardarNombre() {
    const { autor } = this.state;
    if (autor !== '') {
      this.setState({
        isStart: true
      }, () => this.iniciarDatos());
    } else {
      this.setState({
        isEmptyError: true
      });
    }
  }

  renderIngresarNombre() {
    const { autor, isEmptyError } = this.state;
    return (
      <div>
        <Row>
          <h2 style={{ textAlign: 'center', color: '#01a1dd' }}>
            Locutor
          </h2>
          
        </Row>
        <Row>
          <Col s={2} />
          <Col s={8}>
            <h4 style={{ color: '#01a1dd' }}>
              Ingresa tu nombre:
            </h4>
            {/* <Checkbox
            id="Checkbox_3"
            label="Solo Prioridad"
            value="Prioridad"
            checked={this.state.static}
            onChange={this.handleChek}
          /> */}
            <input
              type="text"
              onChange={this.onChange.bind(this)}
              value={autor}
            />
            { isEmptyError
              ? (
                <Card className="blue-grey darken-1" textClassName="white-text" title="Error">
                Debes ingresar tu nombre para continuar
                </Card>
              ) : null
            }
            <Button waves="orange" onClick={this.guardarNombre}>
              Guardar
              <Icon right>forward</Icon>
            </Button>
          </Col>
          <Col s={2} />
        </Row>
      </div>
    );
  }

  renderGrabarAudio() {
    const { frase } = this.state;
    const { onSuccess, onError } = this;
    return (
      <div>
        <div>
          <blockquote>
            <h5>{frase.texto}</h5>
          </blockquote>
        </div>
        <hr />
        <div>{!frase.texto?"":
          <RecorderContainer
            onAudio={onSuccess}
            onError={onError}
            changeShowList={this.changeShowList}
          />}
        </div>
      </div>
    );
  }

  renderPantallaPrincipal() {
    const { renderGrabarAudio } = this;
    const {
      isError,
      canRecord,
      mensaje,
      autor
    } = this.state;
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>{autor}</h2>
        <Row>
        <Search
        autor={autor}
        searchPhrase={this.searchPhrase}
        showList={this.state.showList}
        showListF2={this.state.canRecord}
        lista={this.state.lista}
        />
        </Row>
        <h4>{mensaje}</h4>
        {
          !isError && !canRecord
            ? Locutor.renderLoading()
            : null
        }
        <div style={canRecord ? {} : { display: 'none' }}>
          {
            renderGrabarAudio()
          }
        </div>
      </div>
    );
  }

  static renderLoading() {
    return (
      <Col
        s={12}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Preloader flashing />
      </Col>
    );
  }

  renderComponente() {
    const { isStart } = this.state;
    let componente = null;
    if (!isStart) {
      componente = this.renderIngresarNombre();
    } else {
      componente = this.renderPantallaPrincipal();
    }
    return componente;
  }

  render() {
    const componente = this.renderComponente();
    return (
      <div>{componente}</div>
    );
  }
}

export default Locutor;
